import http from "../../../http-common.function";
// import httpf from "../../http-file-upload.function";
var sha1 = require('sha1');

// Call APIs For IndieMovie

const createIndieMovie = (data) => {
  return http.post(`indie_movie/create`, {
    headers: {
        'Content-Type': 'multipart/form-data;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }, data
  });
};


const fetchIndieMovieList = (data) => {
  return http.post(`indie_movie/list`, {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }, data
  });
};
const updateIndieMovie = (data) => {
  return http.post(`indie_movie/update`, {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }, data
  });
};
const changeIndieMovieStatus = (data) => {
  return http.post(`indie_movie/changestatus`, {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }, data
  });
};
const trashIndieMovie = (data) => {
  return http.post(`indie_movie/trash`, {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }, data
  });
};


const IndieMoviePageService = {
  fetchIndieMovieList,
  createIndieMovie,
  updateIndieMovie,
  changeIndieMovieStatus,
  trashIndieMovie,
};
export default IndieMoviePageService;