import http from "../../../http-common.function";
// import httpf from "../../http-file-upload.function";
var sha1 = require('sha1');

// // Call APIs For Support

const createSupport = (data) => {
  return http.post(`support/create`, {
    headers: {
        'Content-Type': 'multipart/form-data;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }, data
  });
};


// const fetchSupportList = (data) => {
//   return http.post(`support/list`, {
//     headers: {
//         'Content-Type': 'application/json;charset=UTF-8',
//         "Access-Control-Allow-Origin": "*",
//     }, data
//   });
// };
// const updateSupport = (data) => {
//   return http.post(`support/update`, {
//     headers: {
//         'Content-Type': 'application/json;charset=UTF-8',
//         "Access-Control-Allow-Origin": "*",
//     }, data
//   });
// };
// const changeSupportStatus = (data) => {
//   return http.post(`support/changestatus`, {
//     headers: {
//         'Content-Type': 'application/json;charset=UTF-8',
//         "Access-Control-Allow-Origin": "*",
//     }, data
//   });
// };
// const trashSupport = (data) => {
//   return http.post(`support/trash`, {
//     headers: {
//         'Content-Type': 'application/json;charset=UTF-8',
//         "Access-Control-Allow-Origin": "*",
//     }, data
//   });
// };


const SupportService = {
  // fetchSupportList,
  createSupport,
  // updateSupport,
  // changeSupportStatus,
  // trashSupport,
};
export default SupportService;