import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { MotionAnimate } from 'react-motion-animate'
import product_bg_img from './assets/product_bg.jpg';

import { NavLink } from "react-router-dom";
import AuthHandler from '../session/authentication/auth';

import googleLoginImg from '../../templates/assets/images/google.png';
import appleLoginImg from '../../templates/assets/images/apple.png';
import SignUpFBLoginImg from '../../templates/assets/images/SignUpFB.png';


import Slider from "react-slick";

import { initializeApp } from "firebase/app";

// import firebase from "firebase/compat/app";
// import * as firebase from 'firebase/app';

import { getAuth, signInWithEmailAndPassword, signInWithPopup, getRedirectResult, signInWithRedirect, FacebookAuthProvider, GoogleAuthProvider, signInWithPhoneNumber, RecaptchaVerifier, OAuthProvider } from "firebase/auth";

import AppleLoginButton from "./AppleLoginButton";

import { createSignin } from "./actions";

import {
  retrieveDistricts,
} from "../homepage/actions/districts";

import {
  retrieveGenres,
} from "../homepage/actions/genres";




const Signinpage = () => {
  const initialData = {
    phone: '',
    otp: '',
    email: '',
    password: '',
  }
  const firebaseConfig = {
    apiKey: "AIzaSyDcyl-BCMvGFQYkwuDVe_BDqVlgDxmmEhk",
    authDomain: "jaam-25e05.firebaseapp.com",
    projectId: "jaam-25e05",
    storageBucket: "jaam-25e05.appspot.com",
    messagingSenderId: "740930455045",
    appId: "1:740930455045:web:d320b3959912672591be6c",
    measurementId: "G-GQL86TE7K2"
  };
  const dispatch = useDispatch();


  const districts_data = useSelector(state => state.DistrictsReducer, shallowEqual);
  const genres_data = useSelector(state => state.GenresReducer, shallowEqual);




  const [query_form_data, setSigninFormData] = useState(initialData);
  const [login_status, setLoginStatus] = useState(false);
  const [login_type_phone, setLoginTypePhone] = useState(true);
  const [login_type_otp, setLoginTypeOTP] = useState(false);
  const [login_type_email, setLoginTypeEmail] = useState(false);
  const [error_status, setErrorStatus] = useState(false);
  const [ip_address, setIpAddress] = useState('');








  // Firebase

  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  auth.languageCode = 'en';

  const facebookProvider = new FacebookAuthProvider();
  var googleProvider = new GoogleAuthProvider();
  const appleProvider = new OAuthProvider('apple.com');

  appleProvider.addScope('email');
  appleProvider.addScope('name');

  appleProvider.setCustomParameters({
    // Localize the Apple authentication screen in French.
    locale: 'fr'
  });

  // For Facebook
  facebookProvider.addScope('user_birthday');
  facebookProvider.setCustomParameters({
    'display': 'popup'
  });

  // For Google
  googleProvider.addScope('https://www.googleapis.com/auth/contacts.readonly');

  googleProvider.setCustomParameters({
    'login_hint': 'user@example.com'
  });



  useEffect(() => {
    let data = {
      datacount: 100,
      page: 1,
    };
    dispatch(retrieveDistricts(data));
    dispatch(retrieveGenres(data));

    const authentication = AuthHandler.getAuthDetails();
    if (authentication.uid !== undefined)
      setLoginStatus(true);

    fetch('https://api.ipify.org?format=json').then(response => {
      return response.json();
    }).then((res) => {
      setIpAddress(res.ip);
      console.log(res.ip)
      // this.myIp = _.get(res, 'ip');
    }).catch((err) => console.error('Problem fetching my IP', err))



    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
        size: 'invisible',
        callback: response => {
          console.log(response);
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          // ...
        },
        'expired-callback': err => {
          console.log(err);
          // Response expired. Ask user to solve reCAPTCHA again.
          // ...
        },
      });
    }
  }, []);

  let handleChange = (event) => {
    const { name, value } = event.target;
    setSigninFormData({ ...query_form_data, [name]: value });
  }

  let handleSubmitSigninForm = () => {
    // console.log(query_form_data.phone)
    let phone = '+91 '+query_form_data.phone;
    let OTP = query_form_data.otp;
    // console.log(phone)
    if (login_type_phone) {
      // var appVerifier = window.recaptchaVerifier;
      var appVerifier = window.recaptchaVerifier;
      console.log("Phone Test", phone)
      signInWithPhoneNumber(auth, phone, appVerifier)
        .then((confirmationResult) => {
          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          console.log("confirmationResult", confirmationResult);

          setLoginTypePhone(false);
          setLoginTypeOTP(true);
          window.confirmationResult = confirmationResult;
          // ...
        }).catch((error) => {
          // Error; SMS not sent
          // ...
          console.log(error);
          // grecaptcha.reset(window.recaptchaWidgetId);

          // // Or, if you haven't stored the widget ID:
          // window.recaptchaVerifier.render().then(function(widgetId) {
          //   grecaptcha.reset(widgetId);
          // })
        });
    }
    if (login_type_otp) {
      setLoginTypePhone(false);
      setLoginTypeOTP(true);
      console.log(OTP)
      window.confirmationResult
      .confirm(OTP)
      .then(result => {
        // OtpResponse(result);
        AuthHandler.setAuthDetails(result);
        let user_data_temp = {
          uid: result.user.uid,
          email: result.user.email,
          authProvider: 'phone',
          lastLogin: new Date(),
          avatar: result.user.photoURL,
          phone: result.user.providerData[0]['phoneNumber'],
          createdOn: new Date(),
          createdOnnew: new Date(),
          district: '',
          ipAddress: ip_address,
          messageToken: '',
          packageInfo: '',
          tacAccepted: true,
          username: result.user.displayName,
          watchingNow: false,
          accountCompleted: true,
          recommendations: []
        };
        //         accountCompleted: true
        // authProvider:''
        // avatar:''
        // createdOnnew Date().toDateString()
        // district: null
        // email: ''
        // ipAddress: []
        // lastLogin:new Date().toDateString()
        // messageToken:''
        // packageInfo:''
        // phone:''
        // recommendations: []
        // tacAccepted: true
        // username: ''
        // watchingNow: false
        dispatch(createSignin(user_data_temp)).then((response) => {
          AuthHandler.setAuthDetails(user_data_temp);
          setLoginStatus(true);
        });
      })
      .catch(error => {
        console.log(error)
        // setErrorStatus(true);
        // setFirebaseErrorMassage('status/failed');
      });
      // .finally(() => {
      //   // setIsSubmitting(false);
      // });
    }
    if (login_type_email) {
      // console.log(query_form_data);
      signInWithEmailAndPassword(auth, query_form_data.email, query_form_data.password)
        .then((userCredential) => {
          const user = userCredential.user;
          // console.log(user);
          AuthHandler.setAuthDetails(user);
          setLoginStatus(true);
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          // console.log(error)
          setErrorStatus(true)
        });
    }
  }

  const appleLogin = () => {
    // signInWithPopup(auth, appleProvider)
    // .then((result) => {
    //   // The signed-in user info.
    //   const user = result.user;
    //   console.log(user)
    //   // Apple credential
    //   const credential = OAuthProvider.credentialFromResult(result);
    //   const accessToken = credential.accessToken;
    //   const idToken = credential.idToken;

    //   // IdP data available using getAdditionalUserInfo(result)
    //   // ...
    // })
    // .catch((error) => {
    //   // Handle Errors here.
    //   const errorCode = error.code;
    //   const errorMessage = error.message;
    //   // The email of the user's account used.
    //   const email = error.customData.email;
    //   // The credential that was used.
    //   const credential = OAuthProvider.credentialFromError(error);
    //   console.log(error);

    //   // ...
    // });



    // // signInWithRedirect(auth, appleProvider);
    // // getRedirectResult(auth)
    // // .then((result) => {
    // //   const credential = OAuthProvider.credentialFromResult(result);
    // //   if (credential) {
    // //     // You can also get the Apple OAuth Access and ID Tokens.
    // //     const accessToken = credential.accessToken;
    // //     const idToken = credential.idToken;
    // //   }
    // //   // The signed-in user info.
    // //   const user = result.user;
    // //   console.log(user);

    // // })
    // // .catch((error) => {
    // //   // Handle Errors here.
    // //   const errorCode = error.code;
    // //   const errorMessage = error.message;
    // //   // The email of the user's account used.
    // //   const email = error.customData.email;
    // //   // The credential that was used.
    // //   const credential = OAuthProvider.credentialFromError(error);
    // //   console.log(error);

    // //   // ...
    // // });

  }

  const googleLogin = () => {
    // console.log("google");
    signInWithPopup(auth, googleProvider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        // console.log(result)
        let user_data_temp = {
          uid: result.user.uid,
          email: result.user.email,
          authProvider: 'google',
          lastLogin: new Date(),
          avatar: result.user.photoURL,
          phone: result.user.providerData[0]['phoneNumber'],
          createdOn: new Date(),
          district: '',
          ipAddress: ip_address,
          messageToken: '',
          packageInfo: '',
          tacAccepted: true,
          username: result.user.displayName,
          watchingNow: false,
          accountCompleted: true,
          recommendations: []
        };
        dispatch(createSignin(user_data_temp));

        AuthHandler.setAuthDetails(user_data_temp);
        setLoginStatus(true);
        // IdP data available using getAdditionalUserInfo(result)
        // ...
      }).catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });

    // // Or 
    // signInWithRedirect(auth, googleProvider);
    // getRedirectResult(auth)
    // .then((result) => {
    //   // This gives you a Google Access Token. You can use it to access Google APIs.
    //   const credential = GoogleAuthProvider.credentialFromResult(result);
    //   const token = credential.accessToken;

    //   // The signed-in user info.
    //   const user = result.user;
    //   console.log(user)
    //   // IdP data available using getAdditionalUserInfo(result)
    //   // ...
    // }).catch((error) => {
    //   // Handle Errors here.
    //   const errorCode = error.code;
    //   const errorMessage = error.message;
    //   // The email of the user's account used.
    //   const email = error.customData.email;
    //   // The AuthCredential type that was used.
    //   const credential = GoogleAuthProvider.credentialFromError(error);
    //   // ...
    // });
  }

  if (login_status === true)
    window.location.href = "/";


  var settings = {
    dots: true,
    infinite: true,
    speed: 300,
    centerMode: true,
    centerPadding: '60px',
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 1,
        }
      }
    ]
  };

  return (
    <>
      <section className="heading_banner_overlay">
        <div className="container">
          <div className="banner-overlay"><h1>Sign In</h1></div>
        </div>
      </section>
      <section className="bg-light pt-5 pb-5" style={{ borderBottom: "1px solid #ddd" }}>
        <div className="container">
          <div className="row">


            <div className="container-fluid">
              <div className="row">
                <div className="card card-default w50pc m-auto mt--70px">
                  {/* <div className="card-header">
                    <h2 className="f-Poppins text-center">Sign Up</h2>
                  </div> */}
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-2"></div>
                      <div className="col-md-8 p-10">
                        {(login_type_phone) ?
                          <div className="col-md-12 mt-3">
                            <div className="form-group">
                              <input className="form-control fs-14px f-Poppins" type="number" placeholder="Please type your mobile no." name="phone" id="phone" value={query_form_data.phone} onChange={handleChange} />
                            </div>
                          </div>
                          : ''}
                        {(login_type_otp) ?
                          <div className="col-md-12 mt-3">
                            <div className="form-group">
                              <input className="form-control fs-14px f-Poppins" type="number" placeholder="Please type your One Time Password (OTP)" name="otp" id="otp" value={query_form_data.otp} onChange={handleChange} />
                            </div>
                          </div>
                          : ''}
                        {(login_type_email) ?
                          <div className="row">
                            <div className="col-md-12 mt-3">
                              <div className="form-group">
                                <input className="form-control fs-14px f-Poppins" type="email" placeholder="Please type your E-mail" name="email" id="email" value={query_form_data.email} onChange={handleChange} />
                              </div>
                            </div>
                            <div className="col-md-12 mt-3">
                              <div className="form-group">
                                <input className="form-control fs-14px f-Poppins" type="password" placeholder="Please type password" name="password" id="password" value={query_form_data.password} onChange={handleChange} />
                              </div>
                            </div>
                          </div>
                          : ''}
                        <div className="row">
                          <div className="col-md-12 mt-3">
                            <button className="btn btn-theme btn-block" type="button" onClick={handleSubmitSigninForm}>
                              {(login_type_phone) ?
                                "Generate OTP"
                                :
                                (login_type_otp) ?
                                  "Verify OTP"
                                  :
                                  (login_type_email) ?
                                    "Sign In"
                                    :
                                    ""
                              }
                            </button>
                            <br /><br />
                            <img src={googleLoginImg} className="login-image-buttons" onClick={googleLogin} />
                            {/* <img src={appleLoginImg} className="login-image-buttons" onClick={appleLogin} /> */}
                            {/* <AppleLoginButton /> */}
                            {/* <img src={SignUpFBLoginImg} className="login-image-buttons" /> */}
                          </div>
                          <div className="col-md-12 mt-3 mb-1 text-center">
                            <label className="fg-red">{error_status ? "Invalid Credentials!" : ''}</label>
                          </div>
                          {/* <div className="col-md-12 mt-3">
                            <span className="">Don't have an account ? <NavLink to="/sign_up">Sign Up Now!</NavLink></span>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="recaptcha-container" className="justify-center flex"></div>
      </section>
    </>
  );
};

const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <span className={`fa fa-angle-left slick-arrow-prev slick-arrow`} aria-hidden="true"
      style={{ ...style }}
      onClick={onClick}></span>
  );
}

const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <span className={`fa fa-angle-right slick-arrow-next slick-arrow`} aria-hidden="true"
      style={{ ...style }}
      onClick={onClick}></span>
  );
}
export default Signinpage;