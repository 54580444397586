

// https://www.bezkoder.com/react-crud-web-api/          For Class Components
// https://www.bezkoder.com/react-hooks-redux-crud/      For Functional Components



import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  createSupport
} from './actions/index'

const Supportpage = () => {
  let InitialData = {
    name: '',
    email: '',
    describe: '',
  }
  let InitialErrorData = {
    key: '',
    error_message: '',
  }
  let [supportForm, setSupportForm] = useState(InitialData);
  let [error, setError] = useState(InitialErrorData);
  const [loading, setLoading] = useState(true);
  const [thankyou, setThankyou] = useState(false);
  const dispatch = useDispatch();

  let handleChange = (event) => {
    const { name, value } = event.target;
    setSupportForm({ ...supportForm, [name]: value });
    setError(InitialErrorData);
  }

  let handleSave = () => {
    if (supportForm.name == '') {
      setError({ key: 'name', error_message: 'Name is required.' });
    } else if (supportForm.email == '') {
      setError({ key: 'email', error_message: 'Email is required.' });
    } else if (supportForm.describe == '') {
      setError({ key: 'describe', error_message: 'Describe your issue to proceed.' });
    } else {
      setLoading(true);
      dispatch(createSupport(supportForm)).then((response) => {
        if (response && response.data && response.data.length > 0 && response.data[0]['id']) {
          setLoading(false);
          setThankyou(true);
        } else {
          setLoading(false);
        }
        setLoading(false);
      });
    }
  }


  return (
    <>
      <div className="sub-header">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-sm-12">
              <nav aria-label="breadcrumb" className="text-center breadcrumb-nav">
                <h2 className="Page-title">Support</h2>
                <ol className="breadcrumb">
                  <li>
                    <i className="fa fa-home"></i>
                    <a href="/">Home</a>
                    <i className="fa fa-angle-right"></i>
                  </li>
                  <li><a href="/support">Support</a></li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <div className="main-content">
        <div className="container-fluid">
          <div className="row">
            <div className="offset-md-3 col-md-6">
              <div className="card" style={{ background: '#2D2D2D' }}>
                <div className="card-header">
                  <div className="container-fluid">
                    <h2 className="mt-3"><span className="heading--gradient">Support</span></h2>
                    <h4 className="mt-1">Are you having trouble ? He are here to help you.</h4>
                  </div>
                </div>
                {!thankyou ?
                  <>
                    <div className="card-body">
                      <div className="container-fluid">
                        <div className="row">
                          <form className="contactform">
                            <div className="col-md-12 mt-5">
                              <div className="form-group">
                                <h5>Name</h5>
                                <input onChange={handleChange} className="form-control fs-15px" type="text" name="name" value={supportForm.name} placeholder="" />
                                <p className="fg-white">{error.key == 'name' ? error.error_message : ''}</p>
                              </div>
                            </div>
                            <div className="col-md-12 mt-5">
                              <div className="form-group">
                                <h5>Email</h5>
                                <input onChange={handleChange} className="form-control fs-15px" type="text" name="email" value={supportForm.email} placeholder="" />
                                <p className="fg-white">{error.key == 'email' ? error.error_message : ''}</p>
                              </div>
                            </div>
                            <div className="col-md-12 mt-5">
                              <div className="form-group">
                                <h5>Describe Issue</h5>
                                <textarea onChange={handleChange} className="form-control fs-15px" type="text" name="describe" value={supportForm.describe} placeholder=""></textarea>
                                <p className="fg-white">{error.key == 'describe' ? error.error_message : ''}</p>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer text-center">
                      <button className="btn btn-default" type="button" onClick={handleSave}>Send</button>
                    </div>
                  </>
                  :
                  <div className="card-body">
                    <h4 className="mt-1">Thank You. Your request reached to us. We will contact you shortly</h4>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid text-center mt-5">
          <h5 className="mt-1">or mail us on <a href="mailto:support@jaam.tv">support@jaam.tv</a></h5>
        </div>
      </div>
    </>
  );
};


export default Supportpage;