import {
  CREATE_SUPPORT,
} from "./type";
import SupportService from "../service/support.function.service";
export const createSupport = (data) => async (dispatch) => {
  try {
    const res = await SupportService.createSupport(data);
    dispatch({
      type: CREATE_SUPPORT,
      payload: res.data,
    });
    // console.log(data)
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};