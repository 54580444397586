import http from "../../../http-common.function";
// import httpf from "../../http-file-upload.function";
var sha1 = require('sha1');


// const fileUpload = (data) => {
//   return httpf.post(`webseries/upload`, data);
// };

// Call APIs For Webseries

const createWebseries = (data) => {
  return http.post(`webseries/create`, {
    headers: {
        'Content-Type': 'multipart/form-data;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }, data
  });
};


const fetchWebseriesList = (data) => {
  return http.post(`webseries/list`, {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }, data
  });
};
const updateWebseries = (data) => {
  return http.post(`webseries/update`, {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }, data
  });
};
const changeWebseriesStatus = (data) => {
  return http.post(`webseries/changestatus`, {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }, data
  });
};
const trashWebseries = (data) => {
  return http.post(`webseries/trash`, {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }, data
  });
};



const WebseriesPageService = {
  fetchWebseriesList,
  createWebseries,
  updateWebseries,
  changeWebseriesStatus,
  trashWebseries,
};
export default WebseriesPageService;