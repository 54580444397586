

// https://www.bezkoder.com/react-crud-web-api/          For Class Components
// https://www.bezkoder.com/react-hooks-redux-crud/      For Functional Components



import React, { useState, useEffect } from "react";


const Homepage = () => {
  return (
    <>
      <div className="sub-header">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-sm-12">
              <nav aria-label="breadcrumb" className="text-center breadcrumb-nav">
                <h2 className="Page-title">Refund policy</h2>
                <ol className="breadcrumb">
                  <li>
                    <i className="fa fa-home"></i>
                    <a href="/">Home</a>
                    <i className="fa fa-angle-right"></i>
                  </li>
                  <li><a href="/refund-policy">Refund policy</a></li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <div className="main-content">
        <section className="terms">
          <div class="container">










            <h1 class="kb-title Poppins">Jaam Refund Policy</h1>







            <div class="pane-wrapper">

              <div class="left-pane">









                <div class="card">

                  <div class="card-body">
                    <div class="rte">

                      <p class="h6 line-height-28px fw-300 Poppins"><span class="s1"><strong>What is the return policy?</strong><br /></span><span class="s1">Returns are accepted within 30 days of purchase.&nbsp;Personalized or custom products can’t be returned. P<span>roducts marked as non-returnable within the product's description cannot be returned.</span></span></p><br /><br />
                      <p class="h6 line-height-28px fw-300 Poppins"><span class="s1"><strong>What if the wrong item is sent, or the product is defective?</strong><br /></span><span class="s1">If you get the wrong item or defective product, please contact Customer Service for help with your order.</span></p><br /><br />
                      <p class="h6 line-height-28px fw-300 Poppins"><span class="s1"><strong>Can personalized or custom products be returned?</strong><br /></span><span class="s1">All personalized or custom products are final sale and cannot be returned.</span></p><br /><br />
                      <p class="h6 line-height-28px fw-300 Poppins"><span class="s1"><strong>Is return shipping free?</strong><br /></span><span class="s1">No, return shipping fees are not covered.</span></p><br /><br />
                      <p class="h6 line-height-28px fw-300 Poppins"><span class="s1"><strong>How to track my return?</strong><br /></span><span class="s1">Once a return is processed, you will receive an email notification. For more information on a return that is in process, please contact Customer Service.</span></p><br /><br />
                      <p class="h6 line-height-28px fw-300 Poppins"><span class="s1"><strong>How long do refunds take?</strong><br /></span><span class="s1">Once a refund is processed, it may take 7-10 business days before showing up on your billing statement.</span></p><br /><br />
                      <div class="acc__menu js-acc-menu is-open">
                        <div class="acc__menu-inner">
                          <br /><br />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>










              </div>

            </div>
          </div>
        </section>
      </div>
    </>
  );
};

const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <span className={`fa fa-angle-left slick-arrow-prev slick-arrow`} aria-hidden="true"
      style={{ ...style }}
      onClick={onClick}></span>
  );
}

const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <span className={`fa fa-angle-right slick-arrow-next slick-arrow`} aria-hidden="true"
      style={{ ...style }}
      onClick={onClick}></span>
  );
}


export default Homepage;