
import React, { useState, useEffect } from "react";

import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {NavLink} from 'react-router-dom';

import app_logo from './assets/images/Jaam.png';
import app_logo2 from './assets/images/logo_sm.png';

// import {
// 	retrievePages,
// } from "../client/homepage/actions/index";

import AuthHandler from '../client/session/authentication/auth';

const HeaderComponent = () => {
	const [login_status, setLoginStatus] = useState(false);
	const [profile_status, setProfileStatus] = useState(false);
	const [auth_handler, setAuthHandler] = useState(false);
	// const pages_data = useSelector(state => state.MenuReducer, shallowEqual);

	// const dispatch = useDispatch();

	useEffect(() => {
		const auth = AuthHandler.getAuthDetails();
		setAuthHandler(auth);
		if(auth.uid !== undefined)
		  setLoginStatus(true);
	// 	let data = {
	// 		position: 'navigationbar',
	// 		is_published: 1,
	// 		status: 1,
	// 		is_delete: 0,
	// 	};
	// 	dispatch(retrievePages(data));
	}, []);

	let toggleDropdownHandler = () => {
		if(profile_status)
			setProfileStatus(false)
		else 
			setProfileStatus(true)
	}
	return(
		<section className="section1">
			<nav className="navbar navbar-expand-lg bg-body-tertiary">
				<div className="container-fluid pl-100 pr-100">
					<NavLink className="navbar-brand" to="/home">
						<img className='h50px' src={app_logo} />
					</NavLink>
					{/* "https://www.zee5.com/images/ZEE5_logo.svg?ver=3.10.2" */}
					<button className="navbar-toggler" type="button" data-bs-toggle="collapse"
						data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
						aria-expanded="false" aria-label="Toggle navigation">
						<span className="navbar-toggler-icon"></span>
					</button>
					<div className="collapse navbar-collapse" id="navbarSupportedContent">
						<ul className="navbar-nav me-auto mb-lg-0">
							{/* {pages_data && pages_data.data && (pages_data.data.length > 0) ? 
							pages_data.data.map((value, index) => {
							return( */}
								{/* <li className="nav-item">
									<NavLink className="nav-link" to={"/"+value.menu_seo_link}>{value.menu_name}</NavLink>
								</li> */}
								<li className="nav-item">
									<NavLink className="nav-link" to={"/home"}>Home</NavLink>
								</li>
								<li className="nav-item">
									<NavLink className="nav-link" to={"/shows"}>Web Series</NavLink>
								</li>
								<li className="nav-item">
									<NavLink className="nav-link" to={"/movies"}>Movies</NavLink>
								</li>
								{/* <li className="nav-item">
									<NavLink className="nav-link" to={"/pricing"}>Pricing</NavLink>
								</li> */}
								<li className="nav-item">
									<NavLink className="nav-link" to={"/support"}>Support</NavLink>
								</li>
								<li className="nav-item">
									<NavLink className="nav-link" to={"/contact"}>Contact Us</NavLink>
								</li>
								{/* <li className="nav-item">
									<NavLink className="nav-link" to={"/shows"}>Series</NavLink>
								</li> */}
							{/* );
							})
							:''} */}
						</ul>
						<ul className="navbar-nav me-auto mb-lg-0 text-right">
							{login_status?
							<>
								<li className="nav-item">
									<div className="dropdown">
										<span className="dropdown-toggle" onClick={toggleDropdownHandler}>
											<img src={auth_handler.avatar?auth_handler.avatar:app_logo2} />
										</span>
										<ul className={`dropdown-menu mt-3 ${(profile_status)?'show':''}`}>
											<li><a className="dropdown-item" href={"/profile"}>Profile</a></li>
											<li><a className="dropdown-item" href={"/purchase"}>Purchase history</a></li>
											<li><a className="dropdown-item" href={"/watchlist"}>Watchlist</a></li>
										</ul>
									</div>
									{/* <NavLink className="nav-link" to={"#"}>{auth_handler.email}</NavLink> */}
								</li>
								<li className="nav-item">
									<NavLink className="nav-link" to={"/logout"}>Logout</NavLink>
								</li>
							</>
							:
							<>
								{/* <li className="nav-item">
									<NavLink className="nav-link" to={"/sign_up"}>Sign Up</NavLink>
								</li> */}
								<li className="nav-item">
									<NavLink className="nav-link" to={"/sign_in"}>Sign In</NavLink>
								</li>
							</>
							}
						</ul>
						{/* <form className="d-flex middle-aligned-search-form" role="search">
							<input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
							<button className="btn btn-outline-success" type="submit">Search</button>
						</form> */}
					</div>
				</div>
			</nav>
		</section>
	);
}

export default HeaderComponent;