
import React, { useState, useEffect } from "react";

import { useDispatch, useSelector, shallowEqual } from "react-redux";

import { Loader, Placeholder } from 'rsuite';

import { MotionAnimate } from 'react-motion-animate';

import { useParams } from "react-router-dom";
import Slider from "react-slick";
import { NavLink } from 'react-router-dom';

import {
    retrieveContents,
} from "../homepage/actions/contents";

import GoogleLogo from '../../templates/assets/images/googlelogo.png'
import FacebookLogo from '../../templates/assets/images/fblogo.png'
// import {
//   retrieveAllUnknownTopics,
// } from "./actions/index";




import AuthHandler from '../session/authentication/auth';


const Profilepage = () => {
    // const contents_data = useSelector(state => state.ContentsReducer, shallowEqual);
    // const unknown_topics = useSelector(state => state.ProfilepageReducer);
    const [login_status, setLoginStatus] = useState(false);
    const [auth_handler, setAuthHandler] = useState(false);

    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");

    const [user_data, setUserData] = useState({});
    const [contents_data, setContentsData] = useState({});
    const [loading, setLoading] = useState(true);

    const dispatch = useDispatch();



    useEffect(() => {
        setLoading(true);
        const auth = AuthHandler.getAuthDetails();
        if (auth !== undefined && auth.uid !== undefined) {
            setUserData(auth ? auth : '')
            setFirstname(auth.username ? auth.username.split(' ')[0] : '');
            setLastname(auth.username ? auth.username.split(' ')[auth.username.split(' ').length - 1] : '');
            setEmail(auth.email ? auth.email : '');
            setPhone(auth.phone ? auth.phone : '');
        } else {
            // console.log("Not Logged in")
            window.location.href = "/sign_in";
        }

        let data = {
            datacount: 100,
            page: 1,
            // id: id,
            // type: type,
            user_id: auth.uid,
        };
        dispatch(retrieveContents(data)).then(response => {
            setLoading(false);
            setContentsData(response);
        });

        // const auth = AuthHandler.getAuthDetails();
        setAuthHandler(auth);
        if (auth.uid !== undefined)
            setLoginStatus(true);
        // let data = {
        //     "condition_array": [{
        //         business_id: 1,
        //         parent_id: 0,
        //     }],
        // };
        // dispatch(retrieveAllUnknownTopics(data));
    }, []);
    return (
        <>
            {loading ?
                <div>
                    <Placeholder.Paragraph rows={8} />
                    <Loader center content="loading" />
                </div>
                :
                <>
                    <div className="container-fluid pl-100 pr-100 mt-9">
                        {(contents_data && contents_data.data && contents_data.data.length > 0) ?
                            <h4>Purchase History</h4>
                            : ''}
                        <div className="row">
                            {(contents_data && contents_data.data && contents_data.data.length > 0) ?
                                contents_data.data.map((value_contents, index_contents) => {
                                    return (
                                        <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-2 mt-3 mb-5" key={index_contents}>
                                            <div className="video-block p0">
                                                <div className="video-thumb position-relative thumb-overlay">
                                                    <img alt="" className="img-fluid" src={value_contents.data.poster} />
                                                </div>
                                                <div className="video-content pointer">
                                                    <h2 className={`video-title active`}>
                                                        {value_contents.data.title}
                                                    </h2>
                                                    {/* <div className="video-info d-flex align-items-center">
                                                <span className="video-year">{value_contents.data.duration}</span>
                                                <span className="video-type">{value_contents.data.storyline}</span>
                                            </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                                : ''
                            }
                        </div>
                    </div>
                </>
            }
        </>
    );
};


export default Profilepage;