import {
    RETRIEVE_PAY_STATUS,
    MAKE_PAYMENT,
} from "./type";
import PlayVideoService from "../service/playvideopage.function.service";




export const getPayStatus = (data) => async (dispatch) => {
  try {
    const res = await PlayVideoService.getPayStatus(data);
    console.log(res.data)
    dispatch({
      type: RETRIEVE_PAY_STATUS,
      status: '',
      response: {},
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};




export const razorpaySuccessfulPayment = (data) => async (dispatch) => {
  try {
    const res = await PlayVideoService.razorpaySuccessfulPayment(data);
    console.log(res.data)
    // dispatch({
    //   type: RETRIEVE_PAY_STATUS,
    //   status: '',
    //   response: {},
    //   payload: res.data,
    // });
    return Promise.resolve(res.data);
  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
};


  // export const getPayStatus = (data) => async (dispatch) => {
  //   try {
  //     const res = await PlayVideoService.getPayStatus(data);
  //     dispatch({
  //       type: RETRIEVE_PAY_STATUS,
  //       payload: res.data,
  //     });
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };







  export const makePayment = (data) => async (dispatch) => {
    try {
      const res = await PlayVideoService.makePayment(data);
      dispatch({
        type: MAKE_PAYMENT,
        status: '',
        response: {},
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };