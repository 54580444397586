import http from "../../../http-common.function";
// import httpf from "../../http-file-upload.function";
var sha1 = require('sha1');

// Call APIs For Genres

const createGenres = (data) => {
  return http.post(`genres/create`, {
    headers: {
        'Content-Type': 'multipart/form-data;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }, data
  });
};


const fetchGenresList = (data) => {
  return http.post(`genres/list`, {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }, data
  });
};
const updateGenres = (data) => {
  return http.post(`genres/update`, {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }, data
  });
};
const changeGenresStatus = (data) => {
  return http.post(`genres/changestatus`, {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }, data
  });
};
const trashGenres = (data) => {
  return http.post(`genres/trash`, {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }, data
  });
};


const GenresPageService = {
  fetchGenresList,
  createGenres,
  updateGenres,
  changeGenresStatus,
  trashGenres,
};
export default GenresPageService;