import http from "../../../http-common.function";
// import httpf from "../../http-file-upload.function";
var sha1 = require('sha1');


// const fileUpload = (data) => {
//   return httpf.post(`trending/upload`, data);
// };

// Call APIs For Trending

const createTrending = (data) => {
  return http.post(`trending/create`, {
    headers: {
        'Content-Type': 'multipart/form-data;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }, data
  });
};


const fetchTrendingList = (data) => {
  return http.post(`trending/list`, {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }, data
  });
};
const updateTrending = (data) => {
  return http.post(`trending/update`, {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }, data
  });
};
const changeTrendingStatus = (data) => {
  return http.post(`trending/changestatus`, {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }, data
  });
};
const trashTrending = (data) => {
  return http.post(`trending/trash`, {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }, data
  });
};



const TrendingPageService = {
  fetchTrendingList,
//   fileUpload,
  createTrending,
  updateTrending,
  changeTrendingStatus,
  trashTrending,
};
export default TrendingPageService;