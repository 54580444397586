
import React, { useState, useEffect } from "react";

import { useDispatch, useSelector, shallowEqual } from "react-redux";

import { Loader, Placeholder } from 'rsuite';

import { MotionAnimate } from 'react-motion-animate';

import { useParams } from "react-router-dom";
import Slider from "react-slick";
import { NavLink } from 'react-router-dom';

import {
    retrieveContents,
} from "../homepage/actions/contents";

import GoogleLogo from '../../templates/assets/images/googlelogo.png'
import FacebookLogo from '../../templates/assets/images/fblogo.png'

import app_logo2 from '../../templates/assets/images/logo_sm.png';

// import {
//   retrieveAllUnknownTopics,
// } from "./actions/index";

import AuthHandler from '../session/authentication/auth';


const Profilepage = () => {
    const contents_data = useSelector(state => state.ContentsReducer, shallowEqual);
    // const unknown_topics = useSelector(state => state.ProfilepageReducer);
    const [login_status, setLoginStatus] = useState(false);
    const [auth_handler, setAuthHandler] = useState(false);
    const [avatar, setAvatar] = useState(app_logo2);

    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");

    const [user_data, setUserData] = useState({});
    const [loading, setLoading] = useState(true);

  const [contentData, setContentData] = useState([]);
  const dispatch = useDispatch();



    useEffect(() => {
        setLoading(true);
        const auth = AuthHandler.getAuthDetails();
        // console.log(auth)
        if (auth !== undefined && auth.uid !== undefined) {
            setUserData(auth ? auth : '')
            setFirstname(auth.username ? auth.username.split(' ')[0] : '');
            setLastname(auth.username ? auth.username.split(' ')[auth.username.split(' ').length - 1] : '');
            setEmail(auth.email ? auth.email : '');
            setPhone(auth.phone ? auth.phone : '');
        } else {
            // console.log("Not Logged in")
            window.location.href = "/sign_in";
        }

        let data = {
            datacount: 100,
            page: 1,
            // id: id,
            // type: type,
            user_id: auth.uid,
        };
        dispatch(retrieveContents(data)).then(response => {
            setLoading(false);
            setContentData(data);
        });

        if (auth_handler.providerData && auth_handler.providerData.length > 0 && auth_handler.providerData[0]['photoURL'] || auth_handler.avatar) {
            setAvatar(auth_handler.avatar ? auth_handler.avatar : auth_handler.providerData[0]['photoURL'])
        }

        setAuthHandler(auth);
        if (auth.uid !== undefined)
            setLoginStatus(true);
        // let data = {
        //     "condition_array": [{
        //         business_id: 1,
        //         parent_id: 0,
        //     }],
        // };
        // dispatch(retrieveAllUnknownTopics(data));
    }, []);
    return (
        <>
            {loading ?
                <div>
                    <Placeholder.Paragraph rows={8} />
                    <Loader center content="loading" />
                </div>
                :
                <>
                    <main className="form-profile m-auto">
                        <div className="profile-container">
                            <img className="mb-4" src={avatar} alt="" width="72" height="57" />
                            <h1 className="h1 mb-6 fw-normal">{auth_handler ? auth_handler.username : ''}</h1>

                            <div className="form-floating">
                                {auth_handler ?
                                    ((auth_handler.authProvider == 'google') ?
                                        <img className="mb-4" src={GoogleLogo} alt="" width="72" height="57" />
                                        : (auth_handler.authProvider == 'facebook') ?
                                            <img className="mb-4" src={FacebookLogo} alt="" width="72" height="57" />
                                            : '')
                                    : ''}
                            </div>

                            <div className="form-floating">
                                <h4 className="h4 mb-6 fw-normal">{auth_handler ? auth_handler.email : ''}</h4>
                            </div>

                            {/* <div className="form-floating mb-6">
                        <h4 className="h4 mb-6 fw-normal">{auth_handler ? new Date(auth_handler.lastLogin).toString() : ''}</h4>
                    </div> */}
                            <button className="btn btn-lg btn-primary" type="button">Logout</button>
                        </div>
                    </main>
                </>
            }
        </>
    );
};


export default Profilepage;