
import React, { useState, useEffect } from "react";

import { useDispatch, useSelector, shallowEqual } from "react-redux";
// import {
// 	retrievePages,
// } from "../client/homepage/actions/index";

const FooterComponent = () => {
	// const pages_data = useSelector(state => state.MenuReducer, shallowEqual);

	// const dispatch = useDispatch();

	// useEffect(() => {
	// 	let data = {
	// 		position: 'navigationbar',
	// 		is_published: 1,
	// 		status: 1,
	// 		is_delete: 0,
	// 	};
	// 	dispatch(retrievePages(data));
	// }, []);
    return (
        <>
            {/* <div className="main-footer">
                <div className="container-fluid pl-100 pr-100">
                    <div className="row justify-content-lg-between justify-content-center">
                        <div className="col-md-3">
                            <div className="footer-widget">
                                <div className="widget-content">
                                    <div className="footer-logo"><img alt="" className="img-fluid" src={"https://excellencetheme.com/templates/avastream/images/footer-logo.png"} /></div>
                                    <div className="footer-about-text">
                                        <p className="text-muted">Here , write the complete address of the Registered office address along with telephone number.</p>
                                    </div>
                                    <div className="footer-social-icons">
                                        <ul className="list-inline">
                                            <li className="list-inline-item">
                                                <a href="#" title="twitter"><i className="fab fa-2x fa-twitter"></i></a>
                                            </li>
                                            <li className="list-inline-item">
                                                <a href="#" title="facebook"><i className="fab fa-2x fa-facebook-f"></i></a>
                                            </li>
                                            <li className="list-inline-item">
                                                <a href="#" title="instagram"><i className="fab fa-2x fa-instagram"></i></a>
                                            </li>
                                            <li className="list-inline-item">
                                                <a href="#" title="youtube"><i className="fab fa-2x fa-youtube"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="footer-widget">
                                <div className="widget">
                                    <div className="widget-header">
                                        <h2 className="widget-title">Display Type</h2>
                                    </div>
                                    <div className="widget-content footer-menu">
                                        <ul className="f-link list-unstyled mb-0">
                                            <li>
                                                <a href="#">Action</a>
                                            </li>
                                            <li>
                                                <a href="#">Comedy</a>
                                            </li>
                                            <li>
                                                <a href="#">Drama</a>
                                            </li>
                                            <li>
                                                <a href="#">Horror</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="footer-widget">
                                <div className="widget">
                                    <div className="widget-header">
                                        <h2 className="widget-title">Production</h2>
                                    </div>
                                    <div className="widget-content footer-menu">
                                        <ul className="f-link list-unstyled mb-0">
                                            <li>
                                                <a href="#">2018 Year</a>
                                            </li>
                                            <li>
                                                <a href="#">2019 Year</a>
                                            </li>
                                            <li>
                                                <a href="#">2020 Year</a>
                                            </li>
                                            <li>
                                                <a href="#">2021 Year</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="footer-widget">
                                <div className="widget">
                                    <div className="widget-header">
                                        <h2 className="widget-title">Display Quality</h2>
                                    </div>
                                    <div className="widget-content footer-menu">
                                        <ul className="f-link list-unstyled mb-0">
                                            <li>
                                                <a href="#">720p HDTV</a>
                                            </li>
                                            <li>
                                                <a href="#">1080p BluRay</a>
                                            </li>
                                            <li>
                                                <a href="#">720p BluRay</a>
                                            </li>
                                            <li>
                                                <a href="#">1080p WEB-DL</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="copyright">
                <div className="container-fluid pl-100 pr-100">
                    <div className="row justify-content-between">
                        <div className="col-md-6">
                            <p>© Copyright 2023 Jaam, All Rights Reserved</p>
                        </div>
                        <div className="col-md-6">
                            <div className="copyright-menu text-right">
                                <ul>
                                    <li>
                                        <a href="/termscondition">Terms of Service</a>
                                    </li>
                                    <li>
                                        <a href="/privacypolicy">Privacy Policy</a>
                                    </li>
                                    <li>
                                        <a href="/refundpolicy">Refund policy</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default FooterComponent;