import http from "../../../http-common.function";

// Call APIs For Contents

const create = (data) => {
  return http.post(`users/create`, {
    headers: {
        'Content-Type': 'multipart/form-data;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }, data
  });
};


const SigninpageService = {
  create,
};
export default SigninpageService;