import {
    CREATE_USERS,
  } from "../actions/type";
  const initialState = [];
  function SigninpageReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case CREATE_USERS:
        return [...state, payload];
      default:
        return state;
    }
  };
  export {SigninpageReducer};