import {
  CREATE_CONTACT,
} from "./type";
import ContactService from "../service/contact.function.service";
export const createContact = (data) => async (dispatch) => {
  try {
    const res = await ContactService.createContact(data);
    dispatch({
      type: CREATE_CONTACT,
      payload: res.data,
    });
    // console.log(data)
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};