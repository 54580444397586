import http from "../../../http-common.function";
// import httpf from "../../http-file-upload.function";
var sha1 = require('sha1');


// const fileUpload = (data) => {
//   return httpf.post(`videos/upload`, data);
// };

// Call APIs For Videos

const createVideos = (data) => {
  return http.post(`videos/create`, {
    headers: {
        'Content-Type': 'multipart/form-data;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }, data
  });
};


const fetchVideosList = (data) => {
  return http.post(`videos/list`, {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }, data
  });
};
const updateVideos = (data) => {
  return http.post(`videos/update`, {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }, data
  });
};
const changeVideosStatus = (data) => {
  return http.post(`videos/changestatus`, {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }, data
  });
};
const trashVideos = (data) => {
  return http.post(`videos/trash`, {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }, data
  });
};



const VideosPageService = {
  fetchVideosList,
//   fileUpload,
  createVideos,
  updateVideos,
  changeVideosStatus,
  trashVideos,
};
export default VideosPageService;