import { ReactSession } from 'react-client-session';


var Auth = (function() {
    ReactSession.setStoreType("localStorage"); // Cookie && localStorage & sessionStorage
    const auth_data = ReactSession.get("auth");
    var response = (auth_data === undefined)?{}:auth_data;
  
    var getAuthDetails = function() {
        // console.log(auth_data);
        return response;
    };
  
    var setAuthDetails = function(value) {
      response = value; 
      ReactSession.set("auth", value);  
    };
  
    var removeAuthDetails = function() {
      ReactSession.set("auth", {});  
    };
  
    return {
      getAuthDetails: getAuthDetails,
      setAuthDetails: setAuthDetails,
      removeAuthDetails: removeAuthDetails,
    }
  
  })();
  
  export default Auth;