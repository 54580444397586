import http from "../../../http-common.function";
// import httpf from "../../http-file-upload.function";
var sha1 = require('sha1');

// // Call APIs For Subscription

// const createSubscription = (data) => {
//   return http.post(`subscriptions/create`, {
//     headers: {
//         'Content-Type': 'multipart/form-data;charset=UTF-8',
//         "Access-Control-Allow-Origin": "*",
//     }, data
//   });
// };


const fetchSubscriptionList = (data) => {
  return http.post(`subscriptions/list`, {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }, data
  });
};
// const updateSubscription = (data) => {
//   return http.post(`subscriptions/update`, {
//     headers: {
//         'Content-Type': 'application/json;charset=UTF-8',
//         "Access-Control-Allow-Origin": "*",
//     }, data
//   });
// };
// const changeSubscriptionStatus = (data) => {
//   return http.post(`subscriptions/changestatus`, {
//     headers: {
//         'Content-Type': 'application/json;charset=UTF-8',
//         "Access-Control-Allow-Origin": "*",
//     }, data
//   });
// };
// const trashSubscription = (data) => {
//   return http.post(`subscriptions/trash`, {
//     headers: {
//         'Content-Type': 'application/json;charset=UTF-8',
//         "Access-Control-Allow-Origin": "*",
//     }, data
//   });
// };


const SubscriptionPageService = {
  fetchSubscriptionList,
  // createSubscription,
  // updateSubscription,
  // changeSubscriptionStatus,
  // trashSubscription,
};
export default SubscriptionPageService;