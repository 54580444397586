import {
    // FILE_UPLOAD,
    CREATE_WEBSERIES,
    RETRIEVE_WEBSERIES,
    UPDATE_WEBSERIES,
    DELETE_WEBSERIES,
} from "./type";
import WebseriesPageService from "../service/webseriespage.service";

export const createWebseries = (data) => async (dispatch) => {
    try {
        const res = await WebseriesPageService.createWebseries(data);
        // let dataFilter = {};
        // const result = await WebseriesPageService.fetchWebseriesList(dataFilter);

        dispatch({
            type: CREATE_WEBSERIES,
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};
export const retrieveWebseries = (data) => async (dispatch) => {
    try {
        const res = await WebseriesPageService.fetchWebseriesList(data);
        // console.log(res.data)
        dispatch({
            type: RETRIEVE_WEBSERIES,
            status: '',
            response: {},
            payload: res.data.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
};
export const updateWebseries = (data) => async (dispatch) => {
    try {
        const res = await WebseriesPageService.updateWebseries(data);
        dispatch({
            type: UPDATE_WEBSERIES,
            status: '',
            response: {},
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};
export const changeWebseriesStatus = (data) => async (dispatch) => {
    try {
        const res = await WebseriesPageService.changeWebseriesStatus(data);
        dispatch({
            type: UPDATE_WEBSERIES,
            status: '',
            response: {},
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};
export const deleteWebseries = (data) => async (dispatch) => {
    try {
        const res = await WebseriesPageService.trashWebseries(data);
        dispatch({
            type: DELETE_WEBSERIES,
            status: '',
            response: {},
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};