import {
  // FILE_UPLOAD,
  CREATE_SUBSCRIPTION,
  RETRIEVE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION,
  DELETE_SUBSCRIPTION,
} from "./type";
import SubscriptionPageService from "../service/pricing.function.service";



export const createSubscription = (data) => async (dispatch) => {
  try {
    const res = await SubscriptionPageService.createSubscription(data);
    // let dataFilter = {};
    // const result = await SubscriptionPageService.fetchSubscriptionList(dataFilter);

    dispatch({
      type: CREATE_SUBSCRIPTION,
      payload: res.data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};
export const retrieveSubscription = (data) => async (dispatch) => {
  try {
    const res = await SubscriptionPageService.fetchSubscriptionList(data);
    // console.log(res.data)
    dispatch({
      type: RETRIEVE_SUBSCRIPTION,
      status: '',
      response: {},
      payload: res.data.data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
};
export const updateSubscription = (data) => async (dispatch) => {
  try {
    const res = await SubscriptionPageService.updateSubscription(data);
    dispatch({
      type: UPDATE_SUBSCRIPTION,
      status: '',
      response: {},
      payload: res.data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};
export const changeSubscriptionStatus = (data) => async (dispatch) => {
  try {
    const res = await SubscriptionPageService.changeSubscriptionStatus(data);
    dispatch({
      type: UPDATE_SUBSCRIPTION,
      status: '',
      response: {},
      payload: res.data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};
export const deleteSubscription = (data) => async (dispatch) => {
  try {
    const res = await SubscriptionPageService.trashSubscription(data);
    dispatch({
      type: DELETE_SUBSCRIPTION,
      status: '',
      response: {},
      payload: res.data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};