import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AuthHandler from '../session/authentication/auth';


const Logoutpage = () => {
	const [login_status, setLoginStatus] = useState(false);
	useEffect(() => {
        const auth = AuthHandler.getAuthDetails();
        if(auth.uid !== undefined)
        setLoginStatus(true);
        AuthHandler.removeAuthDetails();
        setLoginStatus(false);
	}, []);
    
    
      if(login_status === false)
        window.location.href = "/";
  return (
    <>
    {/* Test */}
    </>
  );
};



export default Logoutpage;