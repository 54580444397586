import http from "../../../http-common.function";
// import httpf from "../../http-file-upload.function";
var sha1 = require('sha1');


// const fileUpload = (data) => {
//   return httpf.post(`districts/upload`, data);
// };

// Call APIs For Districts

const createDistricts = (data) => {
  return http.post(`districts/create`, {
    headers: {
        'Content-Type': 'multipart/form-data;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }, data
  });
};


const fetchDistrictsList = (data) => {
  return http.post(`districts/list`, {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }, data
  });
};
const updateDistricts = (data) => {
  return http.post(`districts/update`, {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }, data
  });
};
const changeDistrictsStatus = (data) => {
  return http.post(`districts/changestatus`, {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }, data
  });
};
const trashDistricts = (data) => {
  return http.post(`districts/trash`, {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }, data
  });
};



const DistrictsPageService = {
  fetchDistrictsList,
  // fileUpload,
  createDistricts,
  updateDistricts,
  changeDistrictsStatus,
  trashDistricts,
};
export default DistrictsPageService;