import http from "../../../http-common.function";
import httppayu from "../../../httppayu-common.function";

const getPayStatus = (data) => {
  return http.post(`pay/json`, {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }, data
  });
};

const razorpaySuccessfulPayment = (data) => {
  return http.post(`pay/razorpay_success`, {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }, data
  });
};


// provided by pradipto da not working into React Project
// https://us-central1-cnd-play.cloudfunctions.net/payu_baaler_hash_sala"
// const getPayStatus = (data) => {
//   // return http.post(`pay/json`, {
//   return httppayu.get(`payu_baaler_hash_sala`, {
//     headers: {
//         'Content-Type': 'application/json;charset=UTF-8',
//         "Access-Control-Allow-Origin": "*",
//     }, data
//   });
// };

const makePayment = (data) => {
  return httppayu.post(`https://secure.payu.in/_payment`, {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }, data
  });
};


const PlayVideoService = {
  getPayStatus,
  makePayment,
  razorpaySuccessfulPayment
};
export default PlayVideoService;