
import React, { useState, useEffect, useRef } from "react";

import { useDispatch, useSelector, shallowEqual } from "react-redux";

import { Loader, Placeholder } from 'rsuite';

import { MotionAnimate } from 'react-motion-animate';

import { useParams } from "react-router-dom";
import Slider from "react-slick";
import { NavLink } from 'react-router-dom';

import ReactHlsPlayer from 'react-hls-player';

import {
  retrieveContents,
} from "../homepage/actions/contents";

import {
  retrieveIndieMovie,
} from "../homepage/actions/indiemovie";

import {
  retrieveTrending,
} from "../homepage/actions/trending";

import {
  retrieveUpcoming,
} from "../homepage/actions/upcoming";

import {
  retrieveDistricts,
} from "../homepage/actions/districts";

import {
  retrieveGenres,
} from "../homepage/actions/genres";

import { getPayStatus } from "./actions";
import AuthHandler from '../session/authentication/auth';


import RazorpayComponent from '../razorpay/razorpay';
import app_logo from '../../templates/assets/images/Jaam.png';




const Video = ({ url }) => {
  const videoRef = useRef();

  useEffect(() => {
    console.log(url)
    videoRef.current?.load();
  }, [url]);

  return (
    <video ref={videoRef} className="video d-block" controls autoPlay>
      <source src={url} />
    </video>
  );

}




const PlayVideopage = () => {
  // const contents_data = useSelector(state => state.ContentsReducer, shallowEqual);
  // const indiemovie_data = useSelector(state => state.IndieMovieReducer, shallowEqual);
  const trending_data = useSelector(state => state.TrendingReducer, shallowEqual);
  // const upcoming_data = useSelector(state => state.UpcomingReducer, shallowEqual);
  // const districts_data = useSelector(state => state.DistrictsReducer, shallowEqual);
  // const genres_data = useSelector(state => state.GenresReducer, shallowEqual);
  const payu_hash = useSelector(state => state.GetPayStatusReducer, shallowEqual);


  const { type, id, episode_id } = useParams();

  const [tab, setTab] = useState('movies');
  const [is_access, setIsAccess] = useState(false);
  const [is_video_extension, setIsVideoExtension] = useState(false);
  const [playVideoURL, setPlayVideoURL] = useState('');
  const [is_trailer_access, setIsTrailerAccess] = useState(true);
  const [is_hash, setIsHash] = useState(false);
  const [udf5, setUdf5] = useState("PayUBiz_NODE_JS_KIT");
  // const [hash, setHash] = useState("84d5fc580e2f2fd604fc8ea87fabfb1243e69d3bacc39f38b2ce22bd6f383665fc2eaf5deafea18b2487e4df12bec8a9fd6f18f3084a5dc679325adf29b1844d");

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [key, setKey] = useState("h0U963");
  const [salt, setSalt] = useState("ILhRpQC7cqRlrb162OBqjqC1TzphaqlR");
  const [txnid, setTxnid] = useState(Date.now());

  const [surl, setSurl] = useState(`${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_URL}/pay/success`);
  const [furl, setFurl] = useState(`${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_URL}/pay/failure`);
  const [curl, setCurl] = useState(`${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_URL}/pay/cancel`);


  const [user_data, setUserData] = useState({});
  const [contents_data, setContentData] = useState({});
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();



  useEffect(() => {
    setLoading(true);
    const auth = AuthHandler.getAuthDetails();
    if (auth !== undefined && auth.uid !== undefined) {
      setUserData(auth ? auth : '')
      setFirstname(auth.username ? auth.username.split(' ')[0] : '');
      setLastname(auth.username ? auth.username.split(' ')[auth.username.split(' ').length - 1] : '');
      setEmail(auth.email ? auth.email : '');
      setPhone(auth.phone ? auth.phone : '');
    } else {
      // console.log("Not Logged in")
      window.location.href = "/sign_in";
    }

    let data = {
      datacount: 100,
      page: 1,
      id: id,
      type: type,
      user_id: auth.uid,
    };
    dispatch(retrieveContents(data)).then((response) => {
      // console.log(response)
      setLoading(false);
      setContentData(response);
    });
    // // dispatch(retrieveIndieMovie(data));
    // dispatch(retrieveTrending(data));
    // // dispatch(retrieveUpcoming(data));
    // // dispatch(retrieveDistricts(data));
    // // dispatch(retrieveGenres(data));

    // console.log(type, id);
  }, []);

  // console.log((contents_data && contents_data.data)?contents_data.data[0]['data']:'')

  let showTab = (tab_type) => {
    setTab(tab_type);
  }

  if (contents_data.data !== undefined && contents_data.data[0] !== undefined && contents_data.data[0]['data'] !== undefined && contents_data.data[0]['data']['pricing'] !== undefined && contents_data.data[0]['data']['pricing']['validity'] !== undefined && contents_data.data[0]['data']['pricing']['validity'] !== '' && is_hash === false) {
    // console.log("contents_data", contents_data)
    setIsHash(true);
    const data_payU = {
      content_type: type,
      content_id: id,
      user_id: user_data.uid,
      days: ((contents_data && contents_data.data && contents_data.data.length > 0) ? contents_data.data[0]['data']['pricing']['validity'] : ''),
      key: key,
      txnid: txnid,
      amount: ((contents_data && contents_data.data && contents_data.data.length > 0) ? contents_data.data[0]['data']['pricing']['amount'] : ''),
      productinfo: ((contents_data && contents_data.data) ? contents_data.data[0]['data']['title'] + '---' + contents_data.data[0]['data']['pricing']['amount'] + '---' + contents_data.data[0]['data']['pricing']['validity'] : ''),
      firstname: firstname,
      lastname: lastname,
      email: email,
      phone: phone,
      surl: surl,
      furl: furl,
      curl: curl,
      udf5: udf5,
      // hash: hash,
    };
    dispatch(getPayStatus(data_payU));
  }

  // console.log(payu_hash);



  let checkPayStatus = () => {
    // // console.log(pay_status_data)
    // let data = {
    //   project_id: project_id,
    //   key: pay_status_data.data.data.key,
    //   txnid: txnid,
    //   // txnid: pay_status_data.data.data.txnid,
    //   amount: amount,
    //   // amount: pay_status_data.data.data.amount,
    //   productinfo: productinfo,
    //   firstname: firstname,
    //   lastname: lastname,
    //   // productinfo: pay_status_data.data.data.productinfo,
    //   // firstname: pay_status_data.data.data.firstname,
    //   // lastname: pay_status_data.data.data.lastname,
    //   email: email,
    //   // email: pay_status_data.data.data.email,
    //   phone: phone,
    //   // phone: pay_status_data.data.data.phone,
    //   surl: pay_status_data.data.data.surl,
    //   furl: pay_status_data.data.data.furl,
    //   curl: pay_status_data.data.data.curl,
    //   udf5: pay_status_data.data.data.udf5,
    //   hash: pay_status_data.data.data.hash,
    // };
    // // console.log(data)
    // dispatch(makePayment(data));
    setIsAccess(true);
  }


  let playVideoAction = () => {
    if(playVideoURL.length == 0) {
      // console.log(contents_data.data[0]['data']['video'])
      let videoURL = ((contents_data && contents_data.data && contents_data.data.length > 0) ? contents_data.data[0]['data']['video'] : '');
      setIsAccess(true);
      setIsTrailerAccess(false);
      // if (type == 'movies')
      setPlayVideoURL(videoURL)
      setIsVideoExtension(videoURL.split(".")[videoURL.split(".").length - 1] == 'm3u8')
    }
  }


  let playTrailerAction = () => {
    setIsAccess(true);
    setIsTrailerAccess(true);
  }





  const settings = {
    autoplay: true,
    autoplaySpeed: 2000,
    dots: true,
    infinite: true,
    speed: 300,
    centerMode: true,
    centerPadding: '60px',
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 1,
        }
      }
    ]
  };




  const settings2 = {
    autoplay: true,
    autoplaySpeed: 2000,
    dots: true,
    infinite: true,
    speed: 300,
    centerMode: true,
    centerPadding: '60px',
    slidesToShow: 5,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 1,
        }
      }
    ]
  };
  return (
    <>
      {loading ?
        <div>
          <Placeholder.Paragraph rows={8} />
          <Loader center content="loading" />
        </div>
        :
        <>
          {/* <div className={(!is_access?"banner-single banner-wrap banner-bg movie-bg2":"block-hidden")}
    style={{backgroundImage: `url(${((contents_data && contents_data.data && contents_data.data.length > 0)?contents_data.data[0]['data']['banner']:'')})`}}
    > */}
          {/* 1 */}
          {/* {((contents_data && contents_data.data && contents_data.data.length > 0)?JSON.stringify(contents_data.data):'')} */}
          {!is_access ?
            <div className={(!is_access ? "banner-single banner-wrap banner-bg movie-bg2" : "block-hidden")}
              style={{ backgroundImage: `url(${((contents_data && contents_data.data && contents_data.data.length > 0) ? contents_data.data[0]['data']['banner'] : '')})` }}
            >
              <div className="container-fluid">
                <div className="banner-content">
                  <div className="transparent-block">
                    <div className="banner-caption">
                      <div className="position-relative mb-4">
                        {(contents_data === undefined || contents_data.data === undefined || contents_data.data.length == 0) ? '' :
                          ((contents_data.data[0]['data']['type'] == 'PREMIUM') && !contents_data.data[0]['data']['is_paid']) ?
                            // For PayU
                            // <form action='https://secure.payu.in/_payment' method='post'>
                            //   <input type="hidden" id="days" name="days" value={((contents_data && contents_data.data && contents_data.data.length > 0) ? contents_data.data[0]['data']['pricing']['validity'] : '')} />
                            //   <input type="hidden" id="key" name="key" value={key} />
                            //   <input type="hidden" id="txnid" name="txnid" value={txnid} />
                            //   <input type="hidden" id="amount" name="amount" value={((contents_data && contents_data.data) ? contents_data.data[0]['data']['pricing']['amount'] : '')} />
                            //   <input type="hidden" id="productinfo" name="productinfo" value={((contents_data && contents_data.data) ? contents_data.data[0]['data']['title'] + '---' + contents_data.data[0]['data']['pricing']['amount'] + '---' + contents_data.data[0]['data']['pricing']['validity'] : '')} />{/*  */}
                            //   <input type="hidden" id="firstname" name="firstname" value={firstname} />
                            //   <input type="hidden" id="lastname" name="lastname" value={lastname} />
                            //   <input type="hidden" id="email" name="email" value={email} />
                            //   <input type="hidden" id="phone" name="phone" value={phone} />
                            //   <input type="hidden" id="surl" name="surl" value={(payu_hash && payu_hash.data && payu_hash.data.data && payu_hash.data.data.surl) ? payu_hash.data.data.surl : ''} />
                            //   <input type="hidden" id="furl" name="furl" value={(payu_hash && payu_hash.data && payu_hash.data.data && payu_hash.data.data.furl) ? payu_hash.data.data.furl : ''} />
                            //   <input type="hidden" id="curl" name="curl" value={(payu_hash && payu_hash.data && payu_hash.data.data && payu_hash.data.data.curl) ? payu_hash.data.data.curl : ''} />
                            //   <input type="hidden" id="udf5" name="udf5" value={udf5} />
                            //   <input type="hidden" id="hash" name="hash" value={(payu_hash && payu_hash.data && payu_hash.data.data && payu_hash.data.data.hash) ? payu_hash.data.data.hash : ''} />
                            //   <input type="submit" id="submitBTN" value="submit" className="align-items-center" style={{ display: "none" }} />

                            //   <label htmlFor="submitBTN">
                            //     <div className="play-icon">
                            //       <div className="circle pulse"></div>
                            //       <div className="circle" style={{ padding: "10px" }}>
                            //         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                            //           <polygon points="40,30 65,50 40,70"></polygon>
                            //         </svg>
                            //       </div>
                            //     </div>
                            //   </label>
                            // </form>
                            // For Razorpay
                            <RazorpayComponent play_button={true} play_button_simple={false} price={((contents_data && contents_data.data) ? contents_data.data[0]['data']['pricing']['amount'] * 100 : '')} logo={app_logo} currency={'INR'} name={'Jaam.tv'} description={((contents_data && contents_data.data) ? contents_data.data[0]['data']['title'] : '')} days={((contents_data && contents_data.data) ? contents_data.data[0]['data']['pricing']['validity'] : '')} content_id={((contents_data && contents_data.data) ? contents_data.data[0]['id'] : '')} content_type={((contents_data && contents_data.data) ? contents_data.data[0]['data']['type'] : '')} />

                            :

                            <>
                              <label htmlFor="submitBTN" onClick={() => { setPlayVideoURL(is_trailer_access ? ((contents_data && contents_data.data && contents_data.data.length > 0) ? contents_data.data[0]['data']['trailer'] : '') : (is_access ? ((contents_data && contents_data.data && contents_data.data.length > 0) ? contents_data.data[0]['data']['video'] : '') : '')); playVideoAction(); }}>
                                <div className="play-icon">
                                  <div className="circle pulse"></div>
                                  <div className="circle" style={{ padding: "10px" }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                                      <polygon points="40,30 65,50 40,70"></polygon>
                                    </svg>
                                  </div>
                                </div>
                              </label>
                              <h2 className="banner-name text-white font-weight-700">Play {((contents_data && contents_data.data && contents_data.data.length > 0) ? contents_data.data[0]['data']['title'] : '')}</h2>
                            </>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            :
            is_trailer_access ?
              <div className={(is_access ? "video-container" : "block-hidden")}>
                {is_video_extension ?
                  <ReactHlsPlayer className="video d-block"
                    src={`${playVideoURL}`}
                    // src={`https://bitdash-a.akamaihd.net/content/sintel/hls/playlist.m3u8`}
                    autoPlay={true}
                    controls={true}
                    width="100%"
                    height="auto"
                  />
                  :
                  <>
                    <Video url={`${playVideoURL}`} />
                    {/* <video className="video d-block" controls autoPlay>
                      <source src={`${playVideoURL}`}></source>
                    </video> */}
                  </>
                }
              </div>
              :
              <div className={(is_access ? "video-container" : "block-hidden")}>
                {is_video_extension ?
                  <ReactHlsPlayer className="video d-block"
                    src={`${playVideoURL}`}
                    // src={`https://bitdash-a.akamaihd.net/content/sintel/hls/playlist.m3u8`}
                    autoPlay={true}
                    controls={true}
                    width="100%"
                    height="auto"
                  />
                  :
                  <>
                    <Video url={`${playVideoURL}`} />
                    {/* <video className="video d-block" controls autoPlay>
                      <source src={`${playVideoURL}`}></source>
                    </video> */}
                  </>
                }
              </div>
          }

          <div className="main-content">
            {/* {`trailer: ${((contents_data && contents_data.data && contents_data.data.length > 0)?contents_data.data[0]['data']['trailer']:'')}`} */}
            {/* {`video: ${((contents_data && contents_data.data && contents_data.data.length > 0)?contents_data.data[0]['data']['video']:'')}`} */}
            <section className="play-details">
              <div className="container-fluid pl-100 pr-100">
                {(contents_data && contents_data.data && contents_data.data.length > 0 && contents_data.data[0]['data']['episodes'] !== undefined && contents_data.data[0]['data']['episodes'].length > 0) ?
                  <h4>Episodes : </h4>
                  : ''}
                <div className="row">
                  {(contents_data && contents_data.data && contents_data.data.length > 0 && contents_data.data[0]['data']['episodes'] !== undefined) ?
                    contents_data.data[0]['data']['episodes'].map((value_episodes, index_episodes) => {
                      return (
                        <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-2 mt-3 mb-5" key={index_episodes}>
                          <div className="video-block p0">
                            <div className="video-thumb position-relative thumb-overlay">
                              {/* <a href={`/play/${type}/${id}/${value_episodes.id}`}> */}
                              <img alt="" className="img-fluid" src={value_episodes.data.thumbnail} onClick={() => { setPlayVideoURL(value_episodes.data.video); playVideoAction(); }} />
                              {/* </a> */}
                            </div>
                            <div className="video-content pointer">
                              <h2 className={`video-title ${(episode_id !== undefined && episode_id == value_episodes.id) ? 'active' : ''}`} onClick={() => { setPlayVideoURL(value_episodes.data.video); playVideoAction(); }}>
                                {/* <a href={`/play/${type}/${id}/${value_episodes.id}`}> */}
                                {value_episodes.data.episodeName}
                                {/* </a> */}
                              </h2>
                              <div className="video-info d-flex align-items-center" onClick={() => { setPlayVideoURL(value_episodes.data.video); playVideoAction(); }}>
                                <span className="video-year">{value_episodes.data.duration}</span>
                                {/* <span className="video-age">{value_episodes.data.releaseDate}</span> */}
                                <span className="video-type">{value_episodes.data.storyline}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                    : ''
                  }
                </div>
              </div>
              <div className="container-fluid pl-100 pr-100 mt-9">
                <div className="row">
                  <div className="col-md-3">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="play-thumb mb-4">
                          <img style={{ width: "100%" }} className="img-fluid" src={`${((contents_data && contents_data.data && contents_data.data.length > 0) ? contents_data.data[0]['data']['poster'] : '')}`} alt="" />
                          <div className="top-badge">
                            <div className="video-badge">
                              <img className="img-fluid" src="https://excellencetheme.com/templates/avastream/images/top-movies.png" alt="" />
                            </div>
                          </div>
                        </div>
                        <div className="thumb-details text-center">
                          {/* <span>24p</span>
                      <span>7.1</span> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-9">
                    <div className="play-details-content">
                      <div className="title-block d-flex align-items-center justify-content-between">
                        <h2 className="play-title">{`${((contents_data && contents_data.data && contents_data.data.length > 0) ? contents_data.data[0]['data']['title'] : '')}`}</h2>
                      </div>
                      <div className="details-info mb-4">
                        <span><i className="icofont-user mr-2" aria-hidden="true"></i> {`${((contents_data && contents_data.data && contents_data.data.length > 0) ? contents_data.data[0]['data']['contentRating'] : '')}`}</span>
                        <span><i className="icofont-user mr-2" aria-hidden="true"></i> {`${((contents_data && contents_data.data && contents_data.data.length > 0) ? contents_data.data[0]['data']['type'] : '')}`}</span>
                        {/* <span><i className="icofont-clock-time mr-2" aria-hidden="true"></i> {`${((contents_data && contents_data.data && contents_data.data.length > 0)?contents_data.data[0]['data']['duration']:'')}`}</span> */}
                        <span><i className="icofont-simple-smile mr-2" aria-hidden="true"></i> {`${((contents_data && contents_data.data && contents_data.data.length > 0) ? contents_data.data[0]['data']['publish'] : '')}`}</span>
                        {(contents_data && contents_data.data && contents_data.data.length > 0 && contents_data.data[0]['data']['genres'] !== undefined) ?
                          contents_data.data[0]['data']['genres'].map((value, index) => {
                            return (
                              <span key={index}><i className="icofont-movie mr-2" aria-hidden="true"></i> {value}</span>
                            )
                          })
                          : ''}
                        {/* <span><i className="icofont-world mr-2" aria-hidden="true"></i> United States</span>  */}
                      </div>
                      <div className="details-desc">
                        <p>{`${((contents_data && contents_data.data && contents_data.data.length > 0) ? contents_data.data[0]['data']['storyline'] : '')}`}</p>
                      </div>
                      <div className="details-desc">
                        <h4>Crews </h4>
                        <hr className="m-2" />
                        <div className="crew_data row">
                          {
                            (contents_data && contents_data.data && contents_data.data.length > 0 && contents_data.data[0]['data']['crews'].length > 0 && contents_data.data[0]['data']['crews'] !== undefined) ?
                              contents_data.data[0]['data']['crews'].map((value, index) => {
                                return (
                                  <div className="col-md-4">
                                    <div className="crew_data row">
                                      <div className="col-md-4"><img className="img-fluid" src={((value.data !== undefined && value.data.picture !== undefined) ? value.data.picture : '')} alt="" /></div>
                                      <div className="col-md-8">
                                        <div className="block-display text-left">Name: {((value.data !== undefined && value.data.name !== undefined) ? value.data.name : '')}</div>
                                        <div className="block-display text-left">Role: {((value.data !== undefined && value.data.role !== undefined) ? value.data.role : '')}</div><br />
                                      </div>
                                    </div>
                                  </div>
                                );
                              })
                              :
                              <div className="text-center">No data Found</div>
                          }
                        </div>
                      </div>
                      <div className="details-desc">
                        <h4>Casts </h4>
                        <hr className="m-2" />
                        <div className="crew_data row">
                          {
                            (contents_data && contents_data.data && contents_data.data.length > 0 && contents_data.data[0]['data']['casts'].length > 0 && contents_data.data[0]['data']['casts'] !== undefined) ?
                              contents_data.data[0]['data']['casts'].map((value, index) => {
                                return (
                                  <div className="col-md-4">
                                    <div className="crew_data row">
                                      <div className="col-md-4"><img className="img-fluid" src={((value.data !== undefined && value.data.picture !== undefined) ? value.data.picture : '')} alt="" /></div>
                                      <div className="col-md-8">
                                        <div className="block-display text-left">Name: {((value.data !== undefined && value.data.name !== undefined) ? value.data.name : '')}</div>
                                        <div className="block-display text-left">Role: {((value.data !== undefined && value.data.role !== undefined) ? value.data.role : '')}</div><br />
                                      </div>
                                    </div>
                                  </div>
                                );
                              })
                              :
                              <div className="text-center">No data Found</div>
                          }
                        </div>
                      </div>
                      {/* <div className="movie-persons mb-4">
                    <div className="person-block">
                      <h5 className="title">Director</h5>
                      <p className="name">Christopher Nolan</p>
                    </div>
                    <div className="person-block">
                      <h5 className="title">Cast</h5>
                      <p>Christian Bale, Michael Cain, Gary Oldman, Anne Hathway, Tom Hardy, Marion
                        Cotillard</p>
                    </div>
                  </div> */}
                      <div className="details-buttons mt-5">
                        <div className="row d-flex align-items-center">
                          <div className="col-6 col-xl mb-xl-0 mb-3">

                            {(contents_data === undefined || contents_data.data === undefined || contents_data.data.length == 0) ? '' :
                              ((contents_data.data[0]['data']['type'] == 'PREMIUM') && !contents_data.data[0]['data']['is_paid']) ?
                                // <form action='https://secure.payu.in/_payment' method='post'>
                                //   <input type="hidden" id="days" name="days" value={((contents_data && contents_data.data && contents_data.data.length > 0) ? contents_data.data[0]['data']['pricing']['validity'] : '')} />
                                //   <input type="hidden" id="key" name="key" value={key} />
                                //   <input type="hidden" id="txnid" name="txnid" value={txnid} />
                                //   <input type="hidden" id="amount" name="amount" value={((contents_data && contents_data.data) ? contents_data.data[0]['data']['pricing']['amount'] : '')} />
                                //   <input type="hidden" id="productinfo" name="productinfo" value={((contents_data && contents_data.data) ? contents_data.data[0]['data']['title'] + '---' + contents_data.data[0]['data']['pricing']['amount'] + '---' + contents_data.data[0]['data']['pricing']['validity'] : '')} />{/*  */}
                                //   <input type="hidden" id="firstname" name="firstname" value={firstname} />
                                //   <input type="hidden" id="lastname" name="lastname" value={lastname} />
                                //   <input type="hidden" id="email" name="email" value={email} />
                                //   <input type="hidden" id="phone" name="phone" value={phone} />
                                //   <input type="hidden" id="surl" name="surl" value={(payu_hash && payu_hash.data && payu_hash.data.data && payu_hash.data.data.surl) ? payu_hash.data.data.surl : ''} />
                                //   <input type="hidden" id="furl" name="furl" value={(payu_hash && payu_hash.data && payu_hash.data.data && payu_hash.data.data.furl) ? payu_hash.data.data.furl : ''} />
                                //   <input type="hidden" id="curl" name="curl" value={(payu_hash && payu_hash.data && payu_hash.data.data && payu_hash.data.data.curl) ? payu_hash.data.data.curl : ''} />
                                //   <input type="hidden" id="udf5" name="udf5" value={udf5} />
                                //   <input type="hidden" id="hash" name="hash" value={(payu_hash && payu_hash.data && payu_hash.data.data && payu_hash.data.data.hash) ? payu_hash.data.data.hash : ''} />
                                //   <button type="submit" id="submitBTN2" value="submit" className="align-items-center w-100 btn d-block hvr-sweep-to-right" style={{ background: "transparent", border: "none" }} >

                                //     {/* <label className="w-100 btn d-block hvr-sweep-to-right"> */}
                                //     {/* <a href="javascript:void(0);" className="btn d-block hvr-sweep-to-right" tabIndex="0" > */}
                                //     <i className="icofont-ui-play mr-2" aria-hidden="true"></i>Play Video
                                //     {/* </a> */}
                                //     {/* </label> */}
                                //   </button>
                                // </form>
                                <>
                                  {/* price={((contents_data && contents_data.data) ? contents_data.data[0]['data']['pricing']['amount']*100 : '')} logo={app_logo} currency={'INR'} name={'Jaam.tv'} description={((contents_data && contents_data.data) ? contents_data.data[0]['data']['title'] : '')} */}
                                  <RazorpayComponent play_button={false} play_button_simple={true} price={((contents_data && contents_data.data) ? contents_data.data[0]['data']['pricing']['amount'] * 100 : '')} logo={app_logo} currency={'INR'} name={'Jaam.tv'} description={((contents_data && contents_data.data) ? contents_data.data[0]['data']['title'] : '')} />
                                </>

                                :

                                <label className="w-100" htmlFor="submitBTN" onClick={() => { setPlayVideoURL(((contents_data && contents_data.data && contents_data.data.length > 0) ? contents_data.data[0]['data']['video'] : '')); playVideoAction(); }}>
                                  <a href="#" className="btn d-block hvr-sweep-to-right"
                                    tabIndex="0" ><i className="icofont-ui-play mr-2" aria-hidden="true"></i>Play Video</a>
                                </label>
                            }
                          </div>
                          {/* <div className="col-6 col-xl mb-xl-0 mb-3">
                        <a href="#" className="btn d-block hvr-sweep-to-right"
                          tabIndex="0"><i className="icofont-plus mr-2" aria-hidden="true"></i>MY List</a>
                      </div> */}
                          <div className="col-6 col-xl mb-xl-0 mb-3">
                            <a id="trailer" className="btn d-block hvr-sweep-to-right" tabIndex="0"
                              data-toggle="modal" data-target="#trailer-modal" aria-hidden="true" onClick={() => { setPlayVideoURL(((contents_data && contents_data.data && contents_data.data.length > 0) ? contents_data.data[0]['data']['trailer'] : '')); playTrailerAction(); }}><i
                                className="icofont-ui-movie mr-2" aria-hidden="true"></i>Trailer</a>
                            <div className="modal fade" id="trailer-modal" tabIndex="0" role="dialog"
                              aria-labelledby="trailer-modal" aria-hidden="true">
                              <div className="modal-dialog modal-lg" role="document" id="trailerModal">
                                <div className="modal-content">
                                  <div className="modal-header">
                                    <h5 className="modal-title">Trailer</h5>
                                    <button type="button" className="close" data-dismiss="modal"
                                      aria-label="Close">
                                      <span aria-hidden="true"><i className="fas fa-times"></i></span>
                                    </button>
                                  </div>
                                  <div className="modal-body">
                                    <video className="video d-block" controls="" loop="">
                                      <source src="video/01-video.mp4" type="video/mp4" />
                                    </video>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fluid pl-100 pr-100">
                <h4>Related Videos</h4>
                <div className="row">
                  {(contents_data && contents_data.data && contents_data.data.length > 0 && contents_data.data[0]['data']['related'] !== undefined) ?
                    contents_data.data[0]['data']['related'].map((value_contents, index_contents) => {
                      return (
                        (value_contents.data.section === 'movies') ?
                          <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-2" key={index_contents}>
                            <div className="video-block p0">
                              <div className="video-thumb position-relative thumb-overlay">
                                <a href={`/play/${value_contents.data.section}/${value_contents.id}`}>
                                  <img alt="" className="img-fluid" src={value_contents.data.poster} /></a>
                              </div>
                              <div className="video-content">
                                <h2 className="video-title"><a href={`/play/${value_contents.data.section}/${value_contents.id}`}>{value_contents.data.title}</a></h2>
                                <div className="video-info d-flex align-items-center">
                                  {/* <span className="video-year">{value_contents.data.description}</span> <span className="video-age">+18</span> */}
                                  {/* <span className="video-type">{value_contents.data.content_type}</span> */}
                                </div>
                              </div>
                            </div>
                          </div>
                          : ''
                      );
                    })
                    : ''
                  }
                </div>
              </div>
            </section>









          </div>
        </>
      }

    </>
  );
};



const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <span className={`fa fa-angle-left slick-arrow-prev slick-arrow`} aria-hidden="true"
      style={{ ...style }}
      onClick={onClick}></span>
  );
}

const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <span className={`fa fa-angle-right slick-arrow-next slick-arrow`} aria-hidden="true"
      style={{ ...style }}
      onClick={onClick}></span>
  );
}


export default PlayVideopage;