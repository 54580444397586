import { useCallback } from "react";
import useRazorpay from "react-razorpay";

import { useDispatch, useSelector, shallowEqual } from "react-redux";

import AuthHandler from '../session/authentication/auth';

import { razorpaySuccessfulPayment } from "../playvideopage/actions";

export default function Razorpay({ play_button, play_button_simple, price, logo, currency, name, description, days, content_id, content_type }) {

    const auth = AuthHandler.getAuthDetails();
    const [Razorpay] = useRazorpay();

    const dispatch = useDispatch();

    // console.log(auth, play_button, play_button_simple, price, logo, currency, name, description)


    const handlePayment = async () => {

        const options = {
            key: `${process.env.REACT_APP_REZORPAY_KEY}`,
            amount: price,
            currency: currency,
            name: name,
            description: description,
            image: logo,
            // order_id: 'order_'+Date.now(),
            // order_id: 'order_123',
            
            handler: (res) => {
                console.log(res);
                let data = {
                    uid: auth.uid,
                    days: days,
                    content_id: content_id,
                    content_type: content_type,
                    razorpay_id: res.razorpay_payment_id,
                };
                dispatch(razorpaySuccessfulPayment(data)).then((response) => {
                    console.log(response)
                    // setLoading(false);
                    // setContentData(response);
                });
            },
            prefill: {
                name: auth?.username,
                email: auth?.email,
                contact: auth?.phone,
            },
            notes: {
                address: "Razorpay Corporate Office",
            },
            theme: {
                color: "#3399cc",
            },
        };

        const rzpay = new Razorpay(options);
        rzpay.open();
    };

    return (
        <>
            {
                play_button ?
                    <div onClick={handlePayment}>

                        <label htmlFor="submitBTN">
                            <div className="play-icon">
                                <div className="circle pulse"></div>
                                <div className="circle" style={{ padding: "10px" }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                                        <polygon points="40,30 65,50 40,70"></polygon>
                                    </svg>
                                </div>
                            </div>
                        </label>
                        <h2 className="banner-name text-white font-weight-700">Play {description}</h2>
                    </div>
                    : play_button_simple ?
                    <>
                        <button type="button" id="submitBTN2" onClick={handlePayment} className="align-items-center w-100 btn d-block hvr-sweep-to-right" style={{ background: "transparent", border: "none" }} >
                        <i className="icofont-ui-play mr-2" aria-hidden="true"></i>Play Video
                        </button>
                        </>

                    :
                    <button className="btn btn-default" onClick={handlePayment}>Subscribe</button>

            }
        </>
    );
}