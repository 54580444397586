import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Homepage from './client/homepage/homepage.function';
import Moviespage from './client/movies/movies.function';
import Playvideopage from './client/playvideopage/playvideopage.function';
import Privacypolicypage from './client/privacy_policy/privacy_policy.function';
import Showspage from './client/shows/shows.function';
import Termsconditionspage from './client/terms_conditions/terms_conditions.function';
import RefundPolicypage from './client/refund_policy/refund_policy.function';

import Supportpage from './client/support/support.function';
import Contactpage from './client/contact/contact.function';


import Signuppage from './client/signuppage/signup.function';
import Signinpage from './client/signinpage/signin.function';

import Logoutpage from './client/logout/logout.function';
import Profilepage from './client/profile/profile.function';
import Pricingpage from './client/pricing/pricing.function';
import Watchlistpage from './client/watchlist/watchlist.function';
import Purchasepage from './client/purchase/purchase.function';




import HeaderComponent from './templates/header';
import FooterComponent from './templates/footer';
import './App.css';

import {BrowserRouter, Routes, Route} from 'react-router-dom';

import AuthHandler from './client/session/authentication/auth';

// import AuthHandler from './session/authentication/auth';

const App = () => {
  const [is_authentic, setIsAuthentic] = useState(false)

  useEffect(() => {
    const auth = AuthHandler.getAuthDetails();
    if (auth !== undefined && auth.uid !== undefined) {
      setIsAuthentic(true);
      // setUserData(auth ? auth : '')
      // setFirstname(auth.username ? auth.username.split(' ')[0] : '');
      // setLastname(auth.username ? auth.username.split(' ')[auth.username.split(' ').length - 1] : '');
      // setEmail(auth.email ? auth.email : '');
      // setPhone(auth.phone ? auth.phone : '');
    } else {
      // console.log("Not Logged in")
      // window.location.href = "/sign_in";
    }
  }, [])
  return(
    <>
    {/*<React.StrictMode>*/}
      <BrowserRouter>
      <div className='mainWrapper-root'>
      
      <Routes>
        <Route path="/" element={!is_authentic ? <Signinpage /> : <><HeaderComponent /><Homepage /><FooterComponent /></>} />
        <Route path="/home" element={!is_authentic ? <Signinpage /> : <><HeaderComponent /><Homepage /><FooterComponent /></>} />
        <Route path="/play/:type/:id" element={!is_authentic ? <Signinpage /> : <><HeaderComponent /><Playvideopage /><FooterComponent /></>} />
        <Route path="/play/:type/:id/:episode_id" element={!is_authentic ? <Signinpage /> : <><HeaderComponent /><Playvideopage /><FooterComponent /></>} />
        <Route path="/login" element={!is_authentic ? <Signinpage /> : <><HeaderComponent /><Homepage /><FooterComponent /></>} />
        <Route path="/logout" element={!is_authentic ? <Signinpage /> : <Logoutpage />} />
        <Route path="/profile" element={!is_authentic ? <Signinpage /> : <><HeaderComponent /><Profilepage /><FooterComponent /></>} />
        <Route path="/pricing" element={!is_authentic ? <Signinpage /> : <><HeaderComponent /><Pricingpage /><FooterComponent /></>} />
        <Route path="/support" element={!is_authentic ? <Signinpage /> : <><HeaderComponent /><Supportpage /><FooterComponent /></>} />
        <Route path="/contact" element={!is_authentic ? <Signinpage /> : <><HeaderComponent /><Contactpage /><FooterComponent /></>} />
        <Route path="/purchase" element={!is_authentic ? <Signinpage /> : <><HeaderComponent /><Purchasepage /><FooterComponent /></>} />
        <Route path="/watchlist" element={!is_authentic ? <Signinpage /> : <><HeaderComponent /><Watchlistpage /><FooterComponent /></>} />
        <Route path="/sign_up" element={!is_authentic ? <Signinpage /> : <><HeaderComponent /><Homepage /><FooterComponent /></>} />
        <Route path="/sign_in" element={!is_authentic ? <Signinpage /> : <><HeaderComponent /><Homepage /><FooterComponent /></>} />
        <Route path="/movies" element={!is_authentic ? <Signinpage /> : <><HeaderComponent /><Moviespage /><FooterComponent /></>} />
        <Route path="/privacypolicy" element={!is_authentic ? <Signinpage /> : <><HeaderComponent /><Privacypolicypage /><FooterComponent /></>} />
        <Route path="/shows" element={!is_authentic ? <Signinpage /> : <><HeaderComponent /><Showspage /><FooterComponent /></>} />
        <Route path="/termscondition" element={!is_authentic ? <Signinpage /> : <><HeaderComponent /><Termsconditionspage /><FooterComponent /></>} />
        <Route path="/refundpolicy" element={!is_authentic ? <Signinpage /> : <><HeaderComponent /><RefundPolicypage /><FooterComponent /></>} />
      </Routes>
      </div>
      


      <ToastContainer
      position="bottom-right"
      autoClose={7000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      />
      </BrowserRouter>
    {/*</React.StrictMode>*/}
    </>
  )
}


export default App;