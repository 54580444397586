import http from "../../../http-common.function";
// import httpf from "../../http-file-upload.function";
var sha1 = require('sha1');


// const fileUpload = (data) => {
//   return httpf.post(`upcoming/upload`, data);
// };

// Call APIs For Upcoming

const createUpcoming = (data) => {
  return http.post(`upcoming/create`, {
    headers: {
        'Content-Type': 'multipart/form-data;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }, data
  });
};


const fetchUpcomingList = (data) => {
  return http.post(`upcoming/list`, {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }, data
  });
};
const updateUpcoming = (data) => {
  return http.post(`upcoming/update`, {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }, data
  });
};
const changeUpcomingStatus = (data) => {
  return http.post(`upcoming/changestatus`, {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }, data
  });
};
const trashUpcoming = (data) => {
  return http.post(`upcoming/trash`, {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }, data
  });
};



const UpcomingPageService = {
  fetchUpcomingList,
//   fileUpload,
  createUpcoming,
  updateUpcoming,
  changeUpcomingStatus,
  trashUpcoming,
};
export default UpcomingPageService;