
import React, { useState, useEffect } from "react";

import { useDispatch, useSelector, shallowEqual } from "react-redux";

import { Loader, Placeholder } from 'rsuite';

import { MotionAnimate } from 'react-motion-animate';

import Slider from "react-slick";
import { NavLink } from 'react-router-dom';

import {
  retrieveContents,
} from "../homepage/actions/contents";

import {
  retrieveIndieMovie,
} from "../homepage/actions/indiemovie";

import {
  retrieveTrending,
} from "../homepage/actions/trending";

import {
  retrieveUpcoming,
} from "../homepage/actions/upcoming";

import {
  retrieveDistricts,
} from "../homepage/actions/districts";

import {
  retrieveGenres,
} from "../homepage/actions/genres";

import {
  retrieveMovies,
} from "../homepage/actions/movies";

import {
  retrieveWebseries,
} from "../homepage/actions/webseries";



const Showspage = () => {
  const contents_data = useSelector(state => state.ContentsReducer, shallowEqual);
  // const indiemovie_data = useSelector(state => state.IndieMovieReducer, shallowEqual);
  const trending_data = useSelector(state => state.TrendingReducer, shallowEqual);
  // const upcoming_data = useSelector(state => state.UpcomingReducer, shallowEqual);
  // const districts_data = useSelector(state => state.DistrictsReducer, shallowEqual);
  // const genres_data = useSelector(state => state.GenresReducer, shallowEqual);
  const movies_data = useSelector(state => state.MoviesReducer, shallowEqual);
  const webseries_data = useSelector(state => state.WebseriesReducer, shallowEqual);


  const [moviesData, setMoviesData] = useState([]);
  const [webseriesData, setWebseriesData] = useState([]);
  const [tab, setTab] = useState('movies');
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();



  useEffect(() => {
    setLoading(true);
    let data = {
      // is_delete: 0
      datacount: 100,
      page: 1,
    };
    // dispatch(retrieveContents(data));
    // dispatch(retrieveIndieMovie(data));
    // dispatch(retrieveTrending(data));
    // dispatch(retrieveUpcoming(data));
    // dispatch(retrieveDistricts(data));
    // dispatch(retrieveGenres(data));
    dispatch(retrieveWebseries(data)).then((response) => {
      // console.log(response)
      setLoading(false);
      setWebseriesData(response);
    });
    // dispatch(retrieveMovies(data)).then((response) => {
    //   setMoviesData(response);
    // });
  }, []);



  let showTab = (tab_type) => {
    setTab(tab_type);
  }

  const settings = {
    autoplay: true,
    autoplaySpeed: 2000,
    dots: true,
    infinite: true,
    speed: 300,
    centerMode: true,
    centerPadding: '60px',
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 1,
        }
      }
    ]
  };




  return (
    <>
      {loading ?
        <div>
          <Placeholder.Paragraph rows={8} />
          <Loader center content="loading" />
        </div>
        :
        <>
          <div className="sub-header">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-sm-12">
                  <nav aria-label="breadcrumb" className="text-center breadcrumb-nav">
                    <h2 className="Page-title">TV Shows</h2>
                    <ol className="breadcrumb">
                      <li>
                        <i className="fa fa-home"></i>
                        <a href="/">Home</a>
                        <i className="fa fa-angle-right"></i>
                      </li>
                      <li><a href="/shows">TV Shows</a></li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>











          <section className="main-tabs">
            <div className="container-fluid pl-100 pr-100">
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    {webseriesData && webseriesData.data && (webseriesData.data.length > 0) ?
                      webseriesData.data.map((value_webseries, index_webseries) => {
                        return (
                          <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-2 mt-5" key={index_webseries}>
                            <div className="video-block p0">
                              <div className="video-thumb position-relative thumb-overlay">
                                <NavLink to={`/play/${value_webseries.data.type}/${value_webseries.id}`}>
                                  <img alt="" className="img-fluid" src={value_webseries.data.poster} /></NavLink>
                              </div>
                              <div className="video-content">
                                <h2 className="video-title"><NavLink to={`/play/${value_webseries.data.type}/${value_webseries.id}`}>{value_webseries.data.title}</NavLink></h2>
                                <div className="video-info d-flex align-items-center">
                                  <span className="video-type">{value_webseries.data.title}</span>
                                  {/* <span className="video-year">{value_webseries.description}</span> 
                                    <span className="video-age">+18</span> */}
                                  {/* <span className="video-type">{value_webseries.content_type}</span> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                      : <h3>No data found.</h3>
                    }
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      }



    </>
  );
};

const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <span className={`fa fa-angle-left slick-arrow-prev slick-arrow`} aria-hidden="true"
      style={{ ...style }}
      onClick={onClick}></span>
  );
}

const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <span className={`fa fa-angle-right slick-arrow-next slick-arrow`} aria-hidden="true"
      style={{ ...style }}
      onClick={onClick}></span>
  );
}


export default Showspage;