import http from "../../../http-common.function";
// import httpf from "../../http-file-upload.function";
var sha1 = require('sha1');

// // Call APIs For Contact

const createContact = (data) => {
  return http.post(`contact/create`, {
    headers: {
        'Content-Type': 'multipart/form-data;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }, data
  });
};


// const fetchContactList = (data) => {
//   return http.post(`contact/list`, {
//     headers: {
//         'Content-Type': 'application/json;charset=UTF-8',
//         "Access-Control-Allow-Origin": "*",
//     }, data
//   });
// };
// const updateContact = (data) => {
//   return http.post(`contact/update`, {
//     headers: {
//         'Content-Type': 'application/json;charset=UTF-8',
//         "Access-Control-Allow-Origin": "*",
//     }, data
//   });
// };
// const changeContactStatus = (data) => {
//   return http.post(`contact/changestatus`, {
//     headers: {
//         'Content-Type': 'application/json;charset=UTF-8',
//         "Access-Control-Allow-Origin": "*",
//     }, data
//   });
// };
// const trashContact = (data) => {
//   return http.post(`contact/trash`, {
//     headers: {
//         'Content-Type': 'application/json;charset=UTF-8',
//         "Access-Control-Allow-Origin": "*",
//     }, data
//   });
// };


const ContactService = {
  // fetchContactList,
  createContact,
  // updateContact,
  // changeContactStatus,
  // trashContact,
};
export default ContactService;