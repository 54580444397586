// // import {
// //     UserTabReducer, 
// // } from "./usermanagementpage/reducer/usertabreducer";
// // import {
// //     OrganizationTabReducer, 
// // } from "./usermanagementpage/reducer/organizationtabreducer";
// // import {
// //     ResourceTabReducer, 
// // } from "./roomspage/reducer/resourcetabreducer";
// // import {
// //     LocationTabReducer, 
// // } from "./roomspage/reducer/locationtabreducer";
// // import {
// //     ReservePageReducer, ReservePageUIReducer,
// // } from "./reservepage/reducer/reservepagereducer";

// import { MenuReducer, BannerReducer, SectionReducer, ContentReducer, ProjectReducer, SeriesReducer, EpisodesReducer, VideosReducer } from "./client/homepage/reducer/homepage"

// import { GetPayStatusReducer, MakePaymentReducer } from './client/playvideopage/reducer/playvideo';

// import { combineReducers } from "redux";


// const rootReducer = combineReducers({
//     MenuReducer: MenuReducer, 
//     BannerReducer: BannerReducer, 
//     SectionReducer: SectionReducer, 
//     ContentReducer: ContentReducer, 
//     ProjectReducer: ProjectReducer, 
//     SeriesReducer: SeriesReducer, 
//     EpisodesReducer: EpisodesReducer, 
//     VideosReducer: VideosReducer,


//     GetPayStatusReducer: GetPayStatusReducer,
//     MakePaymentReducer: MakePaymentReducer,


//     // UserTabReducer: UserTabReducer, 
//     // OrganizationTabReducer: OrganizationTabReducer, 
//     // ResourceTabReducer: ResourceTabReducer, 
//     // LocationTabReducer: LocationTabReducer, 
//     // ReservePageReducer: ReservePageReducer,
//     // ReservePageUIReducer: ReservePageUIReducer,
// });

// export default rootReducer;













import {
    ContentsReducer, 
} from "./client/homepage/reducer/contentsreducer";

import {
    IndieMovieReducer, 
} from "./client/homepage/reducer/indiemoviereducer";

import {
    DistrictsReducer, 
} from "./client/homepage/reducer/districtsreducer";

import {
    GenresReducer, 
} from "./client/homepage/reducer/genresreducer";

import {
    UpcomingReducer, 
} from "./client/homepage/reducer/upcomingreducer";

import {
    VideosReducer, 
} from "./client/homepage/reducer/videosreducer";

import {
    TrendingReducer, 
} from "./client/homepage/reducer/trendingreducer";


import { GetPayStatusReducer, MakePaymentReducer } from './client/playvideopage/reducer/playvideo';


// import {
//     BasicSettingsReducer, 
// } from "./settings/reducer/basicsettingsreducer";


// import {
//     LoginReducer, 
// } from "./login/reducer/loginreducer";


// import {
//     RegisterReducer, 
// } from "./register/reducer/registerreducer";


// import {
//     PaymentCredentialSetupReducer, 
// } from "./settings/reducer/paymentcredentialsetupreducer";


import { SigninpageReducer } from "./client/signinpage/reducer/signinpage";

import {combineReducers} from "redux";


const rootReducer = combineReducers({
    VideosReducer: VideosReducer,
    ContentsReducer: ContentsReducer, 
    IndieMovieReducer: IndieMovieReducer, 

    DistrictsReducer: DistrictsReducer, 
    GenresReducer: GenresReducer, 

    // BasicSettingsReducer: BasicSettingsReducer, 


    // LoginReducer: LoginReducer, 
    // RegisterReducer: RegisterReducer, 

    TrendingReducer: TrendingReducer, 
    UpcomingReducer: UpcomingReducer, 

    GetPayStatusReducer: GetPayStatusReducer,
    // MakePaymentReducer: MakePaymentReducer,


    SigninpageReducer: SigninpageReducer,

    // PaymentCredentialSetupReducer: PaymentCredentialSetupReducer, 
});

export default rootReducer;
