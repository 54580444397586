import http from "../../../http-common.function";
// import httpf from "../../http-file-upload.function";
var sha1 = require('sha1');


// const fileUpload = (data) => {
//   return httpf.post(`contents/upload`, data);
// };

// Call APIs For Contents

const createContents = (data) => {
  return http.post(`contents/create`, {
    headers: {
        'Content-Type': 'multipart/form-data;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }, data
  });
};


const fetchContentsList = (data) => {
  return http.post(`contents/list`, {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }, data
  });
};
const updateContents = (data) => {
  return http.post(`contents/update`, {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }, data
  });
};
const changeContentsStatus = (data) => {
  return http.post(`contents/changestatus`, {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }, data
  });
};
const trashContents = (data) => {
  return http.post(`contents/trash`, {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }, data
  });
};



const ContentsPageService = {
  fetchContentsList,
  // fileUpload,
  createContents,
  updateContents,
  changeContentsStatus,
  trashContents,
};
export default ContentsPageService;