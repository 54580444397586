

// https://www.bezkoder.com/react-crud-web-api/          For Class Components
// https://www.bezkoder.com/react-hooks-redux-crud/      For Functional Components



import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Loader, Placeholder } from 'rsuite';

import {
  retrieveSubscription,
} from './actions/index'


import app_logo from '../../templates/assets/images/Jaam.png';

import RazorpayComponent from "../razorpay/razorpay";

const Pricingpage = () => {
  const [loading, setLoading] = useState(true);

  // const subscription_data = useSelector(state => state.ContentsReducer, shallowEqual);

  const [subscriptionData, setSubscriptionData] = useState([]);


  const dispatch = useDispatch();


  useEffect(() => {
    setLoading(true);
    let data = {
      datacount: 50,
      page: 1,
    };
    dispatch(retrieveSubscription(data)).then((response) => {
      // console.log(response)
      setSubscriptionData(response);
      setLoading(false);
    });
  }, []);
  return (
    <>
      {loading ?
        <div>
          <Placeholder.Paragraph rows={8} />
          <Loader center content="loading" />
        </div>
        :
        <>
          <div className="sub-header">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-sm-12">
                  <nav aria-label="breadcrumb" className="text-center breadcrumb-nav">
                    <h2 className="Page-title">Pricing</h2>
                    <ol className="breadcrumb">
                      <li>
                        <i className="fa fa-home"></i>
                        <a href="/home">Home</a>
                        <i className="fa fa-angle-right"></i>
                      </li>
                      <li><a href="/pricing">Pricing</a></li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>

          <div className="main-content">
            <div className="container-fluid">
              <div className="row">
                <div className="offset-md-2 col-md-8">
                  <div className="row">
                    {subscriptionData && subscriptionData.data && subscriptionData.data.length > 0 ?
                      subscriptionData.data.map((val, index) => {
                        return (
                          <div className="col-md-4">
                            <div className="card">
                              <div className="card-header">
                                <h4 className="text-center">{val.data.title} Plan</h4>
                              </div>
                              <div className="card-body" style={{ background: '#ffffff' }}>
                                <div className="container-fluid">
                                  <h1 className="text-center" style={{ color: '#000000' }}>Rs. {val.data.amount}</h1>
                                  <p>{val.data.description}</p>
                                </div>
                              </div>
                              <div className="card-footer text-center">
                              <RazorpayComponent price={val?.data?.amount > 0 ? val?.data?.amount * 100 : 0} logo={app_logo} currency={'INR'} name={'Jaam.tv'} description={val?.data?.title} />
                              </div>
                            </div>
                          </div>
                        )
                      }) : ''}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      }
    </>
  );
};


export default Pricingpage;