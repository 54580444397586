import {
  CREATE_USERS,
} from "./type";
import SigninpageService from "../service/signinpage.function.service";
export const createSignin = (data) => async (dispatch) => {
  try {
    const res = await SigninpageService.create(data);
    Promise.resolve(res.data);
    dispatch({
      type: CREATE_USERS,
      payload: res.data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

