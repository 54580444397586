

// https://www.bezkoder.com/react-crud-web-api/          For Class Components
// https://www.bezkoder.com/react-hooks-redux-crud/      For Functional Components



import React, { useState, useEffect } from "react";

import { useDispatch, useSelector, shallowEqual } from "react-redux";

import { Loader, Placeholder } from 'rsuite';

import { MotionAnimate } from 'react-motion-animate';

import Slider from "react-slick";
import { NavLink } from 'react-router-dom';

import {
  retrieveVideos,
} from "./actions/videos";

import {
  retrieveContents,
} from "./actions/contents";

import {
  retrieveIndieMovie,
} from "./actions/indiemovie";

import {
  retrieveTrending,
} from "./actions/trending";

import {
  retrieveUpcoming,
} from "./actions/upcoming";

import {
  retrieveDistricts,
} from "./actions/districts";

import {
  retrieveGenres,
} from "./actions/genres";

import {
  retrieveMovies,
} from "./actions/movies";

import {
  retrieveWebseries,
} from "./actions/webseries";

const Homepage = () => {
  const contents_data = useSelector(state => state.ContentsReducer, shallowEqual);
  // const indiemovie_data = useSelector(state => state.IndieMovieReducer, shallowEqual);
  const trending_data = useSelector(state => state.TrendingReducer, shallowEqual);
  const videos_data = useSelector(state => state.VideosReducer, shallowEqual);
  // const upcoming_data = useSelector(state => state.UpcomingReducer, shallowEqual);
  // const districts_data = useSelector(state => state.DistrictsReducer, shallowEqual);
  // const genres_data = useSelector(state => state.GenresReducer, shallowEqual);
  const movies_data = useSelector(state => state.MoviesReducer, shallowEqual);
  const webseries_data = useSelector(state => state.WebseriesReducer, shallowEqual);

  const [videoData, setVideoData] = useState([]);
  const [trendingData, setTrendingData] = useState([]);

  const [moviesData, setMoviesData] = useState([]);
  const [webseriesData, setWebseriesData] = useState([]);
  const [genresData, setGenresData] = useState([]);
  const [selectedGenresData, setSelectedGenresData] = useState('Drama');
  const [genresVideoData, setGenresVideoData] = useState([]);

  const [tab, setTab] = useState('series');
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();



  useEffect(() => {
    setLoading(true);
    let data = {
      // is_delete: 0
      datacount: 50,
      page: 1,
    };
    dispatch(retrieveTrending(data)).then((response) => {
      setTrendingData(response);
      setLoading(false);
    });
    dispatch(retrieveWebseries(data)).then((response) => {
      // console.log(response)
      setWebseriesData(response);
    });
    dispatch(retrieveMovies(data)).then((response) => {
      setMoviesData(response);
    });
    data = {
      // is_delete: 0
      datacount: 100,
      page: 1,
    };
    dispatch(retrieveGenres(data)).then((response) => {
      setGenresData(response);
    });
    data = {
      is_delete: 0,
      datacount: 6,
      page: 1,
      genres: '',
      is_Carousel: true,
    };
    dispatch(retrieveVideos(data)).then((response) => {
      setVideoData(response);
    });
    data = {
      genres: 'Drama',
      datacount: 100,
      page: 1,
    };
    dispatch(retrieveVideos(data)).then((response) => {
      setGenresVideoData(response);
    });
  }, []);



  let showTab = (tab_type) => {
    setTab(tab_type);
  }

  let filterGenres = (genres) => {
    setSelectedGenresData(genres);
    let data = {
      genres: genres,
      datacount: 6,
      page: 1,
    };
    dispatch(retrieveVideos(data)).then((response) => {
      setGenresVideoData(response);
    });
  }

  const settings = {
    autoplay: true,
    autoplaySpeed: 2000,
    dots: true,
    infinite: true,
    speed: 300,
    centerMode: true,
    centerPadding: '60px',
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 1,
        }
      }
    ]
  };




  const settings2 = {
    autoplay: true,
    autoplaySpeed: 2000,
    dots: false,
    infinite: true,
    speed: 300,
    centerMode: true,
    centerPadding: '60px',
    slidesToShow: 5,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 1367,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 4,
        }
      }
    ]
  };
  return (
    <>

      {loading ?
        <div>
          <Placeholder.Paragraph rows={8} />
          <Loader center content="loading" />
        </div>
        :
        <>
          {/* {contents_data && contents_data.data && (contents_data.data.length > 0) ?
        contents_data.data.map((value_section, index_section) => {
          return (
      */}



          <section className="section5 hidden-other p0">
            <div className="container-fluid pl-100 pr-100">
              {/* <h2 className="block-title">Trending</h2> */}
              <Slider {...settings2} className="your-class">

                {(trendingData && trendingData.data && (trendingData.data.length > 0)) ?
                  trendingData.data.map((value_videos, index_videos) => {
                    return (
                      // (value_content.content_id == value_videos.id && value_content.content_type == 'videos') ?
                      <div className="slider_items" key={index_videos}>
                        <div className="video-block">
                          <div className="video-thumb position-relative thumb-overlay">
                            <NavLink to={`/play/${value_videos.data.type}/${value_videos.id}`}>
                              <img alt="" className="img-fluid"
                                src={value_videos.data.poster} /></NavLink>
                          </div>
                          <div className="video-content">
                            <h2 className="video-title"><NavLink to={`/play/${value_videos.data.type}/${value_videos.id}`}>{value_videos.videos_name}</NavLink></h2>
                            <div className="video-info d-flex align-items-center">
                              <span className="video-year">{value_videos.data.title}</span>
                            </div>
                            <div className="video-info d-flex align-items-center mt-5">
                              {/* <span className="video-age">{value_videos.data.storyline}</span> */}
                              <span className="video-type">{value_videos.data.contentRating}</span>
                            </div>
                          </div>
                        </div>
                      </div>


                      // : ''
                    );
                  })
                  : <h3>No data found.</h3>
                }
              </Slider>
            </div>
          </section>


          <section className="section2 hidden-xs">
            <div className="container-fluid p-0">
              <Slider {...settings} className="banner-slider">
                {trendingData && trendingData.data && (trendingData.data.length > 0) ?
                  trendingData.data.map((value_videos, index_videos) => {
                    return (
                      <div className={" h-100 slider-items" + (index_videos + 1)}>
                        <div className="container-fluid slider-item-container position-relative h-100" style={{ backgroundImage: `url('${value_videos.data.banner}')`, backgroundSize: 'contain', backgroundPosition: 'center' }}>
                          <div className="slider-content h-100">
                            <div className="row align-items-center h-100">
                              <div className="col-xl-6 col-lg-12 col-md-12">
                                <h3 className="mb-20"><span className="badge bg-warning text-dark">{value_videos.data.contentRating}</span></h3>
                                <div className="slider-buttons d-flex align-items-center" >
                                  <h2 className="Poppins">{value_videos.data.title}</h2>
                                </div>
                                <div className="slider-buttons d-flex align-items-center" >
                                  <h6 className="Poppins fw-300">{value_videos.data.storyline}</h6>
                                </div>
                                <div className="slider-buttons d-flex align-items-center" >
                                  <h4 className="Poppins">{value_videos.data.genres.join(' | ')} | {value_videos.data.publish}</h4>
                                </div>
                                <div className="slider-buttons d-flex align-items-center" >
                                  <NavLink className="btn hvr-sweep-to-right" to={`/play/${value_videos.data.type}/${value_videos.id}`} tabIndex="-1"><i
                                    aria-hidden="true" className="fa fa-play mr-2"></i> &nbsp; Play Now</NavLink>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                  : <h3>No data found.</h3>
                }
              </Slider>
            </div>
          </section>

          <section className="main-tabs">
            <div className="container-fluid pl-100 pr-100">
              <div className="row">
                <div className="col-md-12">
                  <div className="season-tabs">
                    <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                      <li className={"nav-item"} role="presentation">
                        <NavLink className={`${((tab == 'series') ? 'nav-link show' : 'nav-link')}`} onClick={() => showTab('series')}>Series</NavLink>
                      </li>
                      <li className={"nav-item ml-2"} role="presentation">
                        <NavLink className={`${((tab == 'movies') ? 'nav-link show' : 'nav-link')}`} onClick={() => showTab('movies')}>Movies</NavLink>
                      </li>
                    </ul>
                  </div>
                  <div className="tab-content" id="pills-tabContent">
                    <div id="pills-additions" className={`tab-pane animated fadeInRight  mt-5 ${((tab == 'series') ? 'show active' : '')} `}>
                      <div className="row">
                        {webseriesData && webseriesData.data && (webseriesData.data.length > 0) ?
                          webseriesData.data.map((value_webseries, index_webseries) => {
                            return (
                              <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-2 mt-5" key={index_webseries}>
                                <div className="video-block p0">
                                  <div className="video-thumb position-relative thumb-overlay">
                                    <NavLink to={`/play/${value_webseries.data.type}/${value_webseries.id}`}>
                                      <img alt="" className="img-fluid" src={value_webseries.data.poster} /></NavLink>
                                  </div>
                                  <div className="video-content">
                                    <h2 className="video-title"><NavLink to={`/play/${value_webseries.data.type}/${value_webseries.id}`}>{value_webseries.data.title}</NavLink></h2>
                                    <div className="video-info d-flex align-items-center">
                                      {/* <span className="video-type">{value_webseries.data.title}</span> */}
                                      {/* <span className="video-year">{value_webseries.data.storyline}</span>  */}
                                      {/* <span className="video-age">+18</span> */}
                                      {/* <span className="video-type">{value_webseries.content_type}</span> */}
                                    </div>
                                    <div className="video-info d-flex align-items-center mt-2">
                                      <p className="Poppins fw-300">{value_webseries.data.genres.join(' | ')}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                          : <h3>No data found.</h3>
                        }
                      </div>
                    </div>
                    <div id="pills-additions" className={`tab-pane animated fadeInRight  mt-5 ${((tab == 'movies') ? 'show active' : '')} `}>
                      <div className="row">
                        {moviesData && moviesData.data && (moviesData.data.length > 0) ?
                          moviesData.data.map((value_movies, index_movies) => {
                            return (
                              <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-2 mt-5" key={index_movies}>
                                <div className="video-block p0">
                                  <div className="video-thumb position-relative thumb-overlay">
                                    <NavLink to={`/play/${value_movies.data.type}/${value_movies.id}`}>
                                      <img alt="" className="img-fluid" src={value_movies.data.poster} /></NavLink>
                                  </div>
                                  <div className="video-content">
                                    <h2 className="video-title"><NavLink to={`/play/${value_movies.data.section}/${value_movies.id}`}>{value_movies.data.title}</NavLink></h2>
                                    <div className="video-info d-flex align-items-center">
                                      {/* <span className="video-year">{value_movies.data.description}</span> <span className="video-age">+18</span> */}
                                      {/* <span className="video-type">{value_movies.data.content_type}</span> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                          : <h3>No data found.</h3>
                        }

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="main-tabs">
            <div className="container-fluid pl-100 pr-100">
              <div className="row">
                <div className="col-md-12">
                  <div className="season-tabs">
                    <ul className="nav nav-pills mb-3">
                      {(genresData && genresData.data && (genresData.data.length > 0)) ?
                        genresData.data.map((value_genres, index_genres) => {
                          return (
                            <li className={"nav-item mr-2"}>
                              <NavLink className={`${((selectedGenresData == value_genres.data.name) ? 'nav-link show' : 'nav-link')}`} onClick={() => filterGenres(value_genres.data.name)}>{value_genres.data.name}</NavLink>
                            </li>
                          );
                        })
                        : 'Drama'
                      }
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid pl-100 pr-100">
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    {(genresVideoData && genresVideoData.data && (genresVideoData.data.length > 0)) ?
                      genresVideoData.data.map((value_genres_video, index_genres_video) => {
                        return (
                          <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-2 mt-5" key={index_genres_video}>
                            <div className="video-block p0">
                              <div className="video-thumb position-relative thumb-overlay">
                                <NavLink to={`/play/${value_genres_video.data.type}/${value_genres_video.id}`}>
                                  <img alt="" className="img-fluid" src={value_genres_video.data.poster} /></NavLink>
                              </div>
                              <div className="video-content">
                                <h2 className="video-title"><NavLink to={`/play/${value_genres_video.data.section}/${value_genres_video.id}`}>{value_genres_video.data.title}</NavLink></h2>
                                <div className="video-info d-flex align-items-center">
                                  <span className="video-age">{value_genres_video.data.contentRating}</span>
                                  {/* <span className="video-year">{value_genres_video.data.description}</span>  */}
                                  {/* <span className="video-type">{value_genres_video.data.content_type}</span> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                      : <h3>No data found.</h3>
                    }
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="section4">
            <div className="container-fluid pl-100 pr-100">
              <h2 className="block-title">Trending</h2>
              <Slider {...settings2} className="your-class">

                {(trendingData && trendingData.data && (trendingData.data.length > 0)) ?
                  trendingData.data.map((value_trending, index_trending) => {
                    return (
                      // (value_content.content_id == value_trending.id && value_content.content_type == 'trending') ?
                      <div className="slider_items" key={index_trending}>
                        <div className="video-block">
                          <div className="video-thumb position-relative thumb-overlay">
                            <NavLink to={`/play/${value_trending.data.type}/${value_trending.id}`}>
                              <img alt="" className="img-fluid"
                                src={value_trending.data.poster} /></NavLink>
                          </div>
                          <div className="video-content">
                            <h2 className="video-title"><NavLink to={`/play/${value_trending.data.type}/${value_trending.id}`}>{value_trending.trending_name}</NavLink></h2>
                            <div className="video-info d-flex align-items-center">
                              {/* <span className="video-year">{value_trending.description}</span> <span className="video-age">+18</span> <span
                            className="video-type">{value_trending.content_type}</span> */}
                            </div>
                          </div>
                        </div>
                      </div>


                      // : ''
                    );
                  })
                  : <h3>No data found.</h3>
                }
              </Slider>
            </div>
          </section>
        </>
      }
    </>
  );
};

const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <span className={`fa fa-angle-left slick-arrow-prev slick-arrow`} aria-hidden="true"
      style={{ ...style }}
      onClick={onClick}></span>
  );
}

const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <span className={`fa fa-angle-right slick-arrow-next slick-arrow`} aria-hidden="true"
      style={{ ...style }}
      onClick={onClick}></span>
  );
}


export default Homepage;