import {
    RETRIEVE_PAY_STATUS,
    MAKE_PAYMENT,
  } from "../actions/type";
  const initialState = [];
  function GetPayStatusReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case RETRIEVE_PAY_STATUS:
        // console.log(state, payload)
        state = (payload.error == 1)?initialState:payload;
        return {data: payload};
      default:
        return state;
    }
  };
  function MakePaymentReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case MAKE_PAYMENT:
        return {data: payload};
      default:
        return state;
    }
  };
  export {
    GetPayStatusReducer,
    MakePaymentReducer,
  };