import {
    // FILE_UPLOAD,
    CREATE_MOVIES,
    RETRIEVE_MOVIES,
    UPDATE_MOVIES,
    DELETE_MOVIES,
} from "./type";
import MoviesPageService from "../service/moviespage.service";

export const createMovies = (data) => async (dispatch) => {
    try {
        const res = await MoviesPageService.createMovies(data);
        // let dataFilter = {};
        // const result = await MoviesPageService.fetchMoviesList(dataFilter);

        dispatch({
            type: CREATE_MOVIES,
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};
export const retrieveMovies = (data) => async (dispatch) => {
    try {
        const res = await MoviesPageService.fetchMoviesList(data);
        // console.log(res.data)
        dispatch({
            type: RETRIEVE_MOVIES,
            status: '',
            response: {},
            payload: res.data.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
};
export const updateMovies = (data) => async (dispatch) => {
    try {
        const res = await MoviesPageService.updateMovies(data);
        dispatch({
            type: UPDATE_MOVIES,
            status: '',
            response: {},
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};
export const changeMoviesStatus = (data) => async (dispatch) => {
    try {
        const res = await MoviesPageService.changeMoviesStatus(data);
        dispatch({
            type: UPDATE_MOVIES,
            status: '',
            response: {},
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};
export const deleteMovies = (data) => async (dispatch) => {
    try {
        const res = await MoviesPageService.trashMovies(data);
        dispatch({
            type: DELETE_MOVIES,
            status: '',
            response: {},
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};