// export const FILE_UPLOAD = "FILE_UPLOAD";
export const CREATE_CONTENTS = "CREATE_CONTENTS";   
export const RETRIEVE_CONTENTS = "RETRIEVE_CONTENTS";
export const UPDATE_CONTENTS = "UPDATE_CONTENTS";
export const DELETE_CONTENTS = "DELETE_CONTENTS";

export const CREATE_VIDEOS = "CREATE_VIDEOS";   
export const RETRIEVE_VIDEOS = "RETRIEVE_VIDEOS";
export const UPDATE_VIDEOS = "UPDATE_VIDEOS";
export const DELETE_VIDEOS = "DELETE_VIDEOS";


export const CREATE_INDIE_MOVIE = "CREATE_INDIE_MOVIE";
export const RETRIEVE_INDIE_MOVIE = "RETRIEVE_INDIE_MOVIE";
export const UPDATE_INDIE_MOVIE = "UPDATE_INDIE_MOVIE";
export const DELETE_INDIE_MOVIE = "DELETE_INDIE_MOVIE";


export const CREATE_UPCOMING = "CREATE_UPCOMING";
export const RETRIEVE_UPCOMING = "RETRIEVE_UPCOMING";
export const UPDATE_UPCOMING = "UPDATE_UPCOMING";
export const DELETE_UPCOMING = "DELETE_UPCOMING";


export const CREATE_TRENDING = "CREATE_TRENDING";
export const RETRIEVE_TRENDING = "RETRIEVE_TRENDING";
export const UPDATE_TRENDING = "UPDATE_TRENDING";
export const DELETE_TRENDING = "DELETE_TRENDING";


export const CREATE_DISTRICTS = "CREATE_DISTRICTS";
export const RETRIEVE_DISTRICTS = "RETRIEVE_DISTRICTS";
export const UPDATE_DISTRICTS = "UPDATE_DISTRICTS";
export const DELETE_DISTRICTS = "DELETE_DISTRICTS";


export const CREATE_GENRES = "CREATE_GENRES";
export const RETRIEVE_GENRES = "RETRIEVE_GENRES";
export const UPDATE_GENRES = "UPDATE_GENRES";
export const DELETE_GENRES = "DELETE_GENRES";


export const CREATE_MOVIES = "CREATE_MOVIES";
export const RETRIEVE_MOVIES = "RETRIEVE_MOVIES";
export const UPDATE_MOVIES = "UPDATE_MOVIES";
export const DELETE_MOVIES = "DELETE_MOVIES";


export const CREATE_WEBSERIES = "CREATE_WEBSERIES";
export const RETRIEVE_WEBSERIES = "RETRIEVE_WEBSERIES";
export const UPDATE_WEBSERIES = "UPDATE_WEBSERIES";
export const DELETE_WEBSERIES = "DELETE_WEBSERIES";
